import React, { PropsWithChildren, useEffect, useRef } from "react";
import { msalInstance } from "../..";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import RAFBusinessUnit from "./RAFBusinessUnit";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { RAFEventName, subscribeRAFEvent, unsubscribeRAFEvent } from "../../RAFComponents/helpers/RAFDocumentEvents";

interface IState {
    showBusinessunitContent: boolean;
}

function RAFBusinessUnitDialogContent() {
    let businessunitDialog = useRef<DialogComponent>(null);

    useEffect(() => {
        subscribeRAFEvent(`${RAFEventName.OpenBusinessUnitDialog}`, openBusinessUnitDialog);

        return () => {
            unsubscribeRAFEvent(`${RAFEventName.OpenBusinessUnitDialog}`, openBusinessUnitDialog);
        };
    });
    const [state, setState] = React.useState<IState>({
        showBusinessunitContent: false,
    });
    const businessUnitDialogContent = () => {
        const currentBusinessUnitId = msalInstance.currentBusinessUnitId;
        if (
            isNotNullAndUndefined(state.showBusinessunitContent) &&
            state.showBusinessunitContent === true
        ) {
            return (
                <RAFBusinessUnit
                    businessUnit={currentBusinessUnitId}
                    enable={true}
                    onClose={() => closeBusinessUnitDialog()}
                    uiType='Default'
                />
            );
        } else {
            return <div></div>;
        }
    }

    const openBusinessUnitDialog = () => {
        setState({ showBusinessunitContent: true });
    };
    const closeBusinessUnitDialog = () => {
        setState({ showBusinessunitContent: false });
    };
    return (
        <div>
            {state.showBusinessunitContent === true && (
                <DialogComponent
                    visible={state.showBusinessunitContent}
                    header="Select Business Unit"
                    cssClass="rightDialog full-height divBusinessUnitDialog"
                    id="divBusinessUnitDialog"
                    ref={businessunitDialog}
                    width="450px"
                    height="100%"
                    isModal={true}
                    overlayClick={() =>
                        closeBusinessUnitDialog
                    }
                    showCloseIcon={true}
                    target="body"
                    closeOnEscape={false}
                    close={closeBusinessUnitDialog}
                    content={businessUnitDialogContent}
                ></DialogComponent>
            )}
        </div>
    )
}
export default React.memo(RAFBusinessUnitDialogContent);