import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  NodeSelectEventArgs,
  TreeViewComponent,
} from "@syncfusion/ej2-react-navigations";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import React from "react";
import { msalInstance } from "../..";
import { hideProgress, showProgress } from "../../RAFComponents/helpers/AppHelper";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import { getBusinessUnits } from "../../helpers/SkyUtils";
import { NavigateParamsProps, withRouter } from "../../router";
import './../../styles/treeviewStyle.scss';

interface IProps {
  businessUnit: string;
  enable: boolean;
  onClose: () => void;
  cssclass?: string;
  uiType?: "Default" | "SideMenu";
}

class RAFBusinessUnit extends React.Component<NavigateParamsProps & IProps> {
  constructor(props) {
    super(props);
    //this.selectedBusinessUnit = 'd872bdf5-cab9-47a5-9f16-fc412a15e2d6';
    this.selectedBusinessUnit = props.businessUnit;
  }

  private changeBUDialog: any;
  //private selectedBusinessUnit: any;
  private selectedBusinessUnit: string;
  treeMenuObj: TreeViewComponent | null;

  componentDidMount = () => {
    this.refresh(this.props.enable, this.props.businessUnit);
  };

  private SetHasChild = (inVal) => {
    let outVal = [];
    if (isNotNullAndUndefined(inVal)) {
      //for (var i = 0, len = inVal.length; i < len; i++) {
      //    let item = inVal[i];
      //    const id = item['UID'];
      //    if (inVal.findIndex(x => x['ParentUID'] === id) >= 0) {
      //        outVal.push({ ClientName: item['ClientName'], UID: item['UID'], ParentUID: item['ParentUID'], hasChild: true, expanded: false })
      //    }
      //    else {
      //        outVal.push({ ClientName: item['ClientName'], UID: item['UID'], ParentUID: item['ParentUID'] })
      //    }
      //}
      let objVal = [];
      for (var i = 0, len = inVal.length; i < len; i++) {
        let item = inVal[i];
        const id = item["UID"];
        if (inVal.findIndex((x) => x["ParentUID"] === id) >= 0) {
          objVal.push({
            ClientName: item["ClientName"],
            UID: item["UID"],
            ParentUID: item["ParentUID"],
            hasChild: true,
            expanded: false,
          });
        } else {
          objVal.push({
            ClientName: item["ClientName"],
            UID: item["UID"],
            ParentUID: item["ParentUID"],
          });
        }
      }

      for (var i = 0; i < objVal.length; i++) {
        let item = objVal[i];
        const ParentUID = item["ParentUID"];
        if (objVal.findIndex((x) => x["UID"] === ParentUID) >= 0) {
          outVal.push({
            ClientName: item["ClientName"],
            UID: item["UID"],
            ParentUID: item["ParentUID"],
            hasChild: item["hasChild"],
            expanded: false,
          });
        } else {
          outVal.push({
            ClientName: item["ClientName"],
            UID: item["UID"],
            ParentUID: null,
            hasChild: item["hasChild"],
            expanded: false,
          });
        }
      }
    }
    //outVal[0]['ParentUID'] = null;// selected current business("selected": true)
    //outVal[0]['expanded'] = true;// expanded first node("expanded":true)
    return outVal;
  };

  refresh = (enable, businessUnit?: string) => {
    if (enable) {
      if (isNotNullAndUndefined(businessUnit)) {
        this.selectedBusinessUnit = businessUnit;
      }
      let progressDiv = showProgress("#divBusinessUnitDialog");

      getBusinessUnits()
        .then((response) => {
          hideProgress(progressDiv);
          if (isNotNullAndUndefined(this.treeMenuObj)) {
            this.treeMenuObj.fields = {
              dataSource: this.SetHasChild(response),
              id: "UID",
              parentID: "ParentUID",
              text: "ClientName",
              hasChildren: "hasChild",
            };
            this.treeMenuObj.refresh();
            this.treeMenuObj.expandAll();
            //this.treeMenuObj.selectedNodes = ['d872bdf5-cab9-47a5-9f16-fc412a15e2d6'];
            //this.treeMenuObj.expandedNodes = ['d872bdf5-cab9-47a5-9f16-fc412a15e2d6'];
            this.treeMenuObj.selectedNodes = [this.selectedBusinessUnit];
            this.treeMenuObj.expandedNodes = [this.selectedBusinessUnit];
          }
        })
        .catch((error) => error);
    }
  };

  loginBtnClicked = (nodeData) => {
    let selectedBU = nodeData["UID"];
    let selectedbuName = nodeData["ClientName"];
    if (this.selectedBusinessUnit !== selectedBU) {
      this.changeBUDialog = DialogUtility.confirm({
        animationSettings: { effect: "Fade" },
        cancelButton: { text: "No" },
        closeOnEscape: false,
        content: "Would you like to login to " + selectedbuName + "?",
        okButton: {
          text: "Yes",
          click: () => this.ChangeBusinessUnit(selectedBU, selectedbuName),
        },
        showCloseIcon: true,
        title: "Login Businessunit",
        position: { X: "center", Y: "center" },
        cssClass: "alert-dialog",
      });
    }
  };

  private nodeTemplate(data) {
    return (
      <div className="treeview w-100">
        <div
          className="textcontent"
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          <div className="w-100 pe-2">
            <div className="row flex-nowrap align-items-center">
              <div className="col overflow-auto">
                <span className={`${data["hasChild"] ? "viewTitle" : "viewName"} ecllipseFirstLine word-break-all text-wrap`}>
                  {data["ClientName"]}
                </span>
              </div>
              <div className="col-auto visible-child-div">
                <ButtonComponent type="button"
                  className="custom-button-sm primary-custom-button"
                  content="Login"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.loginBtnClicked(data);
                  }}
                ></ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  private nodeSelected(e: NodeSelectEventArgs) {
    let selectedBU = e.nodeData["id"];
    let selectedbuName = e.nodeData["text"];
    if (this.selectedBusinessUnit !== selectedBU) {
      this.changeBUDialog = DialogUtility.confirm({
        animationSettings: { effect: "Fade" },
        cancelButton: { text: "No" },
        closeOnEscape: false,
        content: "Would you like to login to " + selectedbuName + "?",
        okButton: {
          text: "Yes",
          click: () => this.ChangeBusinessUnit(selectedBU, selectedbuName),
        },
        showCloseIcon: true,
        title: "Login Businessunit",
        position: { X: "center", Y: "center" },
        cssClass: "alert-dialog",
      });
    }
  }

  private ChangeBusinessUnit = (selectedBU, selectedbuName) => {
    this.selectedBusinessUnit = selectedBU;
    this.changeBUDialog.hide();
    //msalInstance.currentBusinessUnitId = selectedBU;
    msalInstance.currentBusinessUnit = {
      UID: selectedBU,
      BusinessUnitName: selectedbuName,
    };
    if (this.props.onClose) {
      this.props.onClose();
    }
    window.location.reload();
  };

  render() {
    return (
      <div>
        {this.props.enable === true && (
          <div className="container-fluid p-0 hover-parent-treeview">
            <TreeViewComponent
              ref={(t) => (this.treeMenuObj = t)}
              cssClass={this.props.uiType === 'Default' ? "custom-treeview" : "e-sidebar__right__treeview fixedTemplate"}
              //nodeSelected={this.nodeSelected.bind(this)}
              nodeTemplate={this.nodeTemplate.bind(this)}
              allowMultiSelection={false}
              fullRowSelect={true}
            />
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(RAFBusinessUnit);
