import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuCategory } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import { navigateToDashboard } from "../../../../helpers/SkyUtils";

interface IProps {
    onUpdatMenuCategory: (menu: string) => void;
}

function NavigationMenuBackButton({ onUpdatMenuCategory, ...props }: IProps) {
    let navigate = useNavigate();

    const backToMainMenu = () => {
        // setSessionStorage(
        //     RMStorageKey.recentClickedMenu,
        //     true,
        //     JSON.stringify([MenuCategory.Default])
        // );
        navigateToDashboard(navigate);
        if (onUpdatMenuCategory) {
            onUpdatMenuCategory(MenuCategory.Default);
        }
    };

    return (
        <div
            className="pointer text-white"
            onClick={() => backToMainMenu()}
        >
            <div className="row gx-2">
                <div className="col-auto">
                    <i className="ps-2 pe-1 fass fa-arrow-left-long"></i>
                </div>
                <div className="col">Back to Main Menu</div>
            </div>
        </div>
    );
}

export default React.memo(NavigationMenuBackButton);