import React from "react";
import { Component } from "react";
import skyloading from "../../images/skytrust-loading.png";
import { isNotNullAndUndefined, IsNullOrWhiteSpace } from "../../RAFComponents/helpers/utils";

class SkyLoadingPanel extends Component<any> {

    private loadingText = this.props.loadingText;

    render() {

        let loadingText = this.props.loadingText;
        let loadingClass = this.props.loadingClass;

        return (

            <div className={isNotNullAndUndefined(loadingClass) ? "row g-0 wrapper-content " + loadingClass : "row g-0 wrapper-content loadingPanel"}>
                <div className="d-flex flex-column align-items-center">
                    <div className="sk-spinner sk-spinner-double-bounce hidden">
                        <div className="sk-double-bounce1"></div>
                        <div className="sk-double-bounce2"></div>
                    </div>
                    <img src={skyloading} width="52" alt="Skyloading" />
                    <span className="loadingText"> {IsNullOrWhiteSpace(this.loadingText) ? "Loading..." : loadingText }</span>
                </div>
            </div>
        );
    }
}

export default SkyLoadingPanel;
