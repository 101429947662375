import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilCallback } from "recoil";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { CurrentWorkspaceContext } from "../../../RAFModules/Common/Providers/WorkspaceContextProvider";

function RAFFooterNavigationMenu() {
  const currentWorkspaceContext = React.useContext(CurrentWorkspaceContext);
  const currentWorkspace = isNotNullAndUndefined(currentWorkspaceContext)
    ? currentWorkspaceContext.currentWorkspace
    : null;

  const rolePermissionsContext = React.useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  useEffect(() => {
    if (isNotNullAndUndefined(currentWorkspace)) {
      setActiveClassName();
    }
  }, [currentWorkspace, location.pathname]);

  let navigate = useNavigate();

  const headertext = [
    { id: "Menu", text: "Menu", iconCss: "fas fa-bars" },
    {
      id: 'MyActions',
      text: `My Actions`,
      iconCss: "fas fa-calendar-days",
      // path: `/bookings`,
    },
    { id: "Home", text: "Home", iconCss: "fas fa-home", path: "/" },
    {
      id: 'MyProfile',
      text: 'My Profile',
      iconCss: "fas fa-user",
      // path: `/raf/list/${CareEsioEntity.CareRecipient.EntityName}`,
    },
    {
      id: 'Inbox',
      text: 'Inbox',
      iconCss: "fas fa-envelope",
      // path: `/Inbox`,
    },
  ];

  const onSelected = useRecoilCallback(({ set, snapshot }) => async (item) => {
    if (isNotNullAndUndefined(item)) {
      if (item["id"] === "Menu") {
        triggerRAFEvent(RAFEventName.ToggleSideMenu, null);
      }
      else {
        setActiveClassName(item);
        navigate(item["path"]);
      }
    }
  });

  const setActiveClassName = (item?) => {
    let selectedItem;
    if (isNotNullAndUndefined(item)) {
      selectedItem = item;
    }
    else {
      const path = window.location.pathname;
      if (path === "/") {
        selectedItem = headertext[2];
      }
      else {
        selectedItem = headertext.slice(3).find((x) => path.includes(x.path));
        if (isNullOrUndefined(selectedItem)) {
          selectedItem = path.includes(headertext[1].path) ? headertext[1] : null;
        }
      }
    }
    // Get all li elements whose id starts with 'footer_tab_item_'
    const items = document.querySelectorAll('li[id^="footer_tab_item_"]');
    // Remove the 'active' class from each item
    items.forEach((item) => item.classList.remove("active"));
    //Add a active class name in selected item
    if (IsNotNullOrWhiteSpace(selectedItem)) {
      let selectedFooterTabItem = document.getElementById(
        `footer_tab_item_${selectedItem.id}`
      );
      if (isNotNullAndUndefined(selectedFooterTabItem)) {
        selectedFooterTabItem.classList.add("active");
      }
    }
  };

  return (
    <div>
      <div className="footer_tab_outer">
        <ul className="footer_tab_items">
          {headertext.map((item, index) => {
            return (
              <li
                key={index}
                className="footer_tab_item"
                onClick={() => onSelected(item)}
                id={`footer_tab_item_${item.id}`}
              >
                <div className="footer_tab_item_icon">
                  <i className={item.iconCss}></i>
                </div>
                <div className="footer_tab_item_text">
                  <span>{item.text}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
export default React.memo(RAFFooterNavigationMenu);
