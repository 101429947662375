export enum SKYPermissionConstants {

  //EmployeeList = "Employee::List",
  // EmployeeDetails = "Employee::Details",
  // EmployeeAdd = "Employee::Add",
  // EmployeeEdit = "Employee::Edit",
  // EmployeeDelete = "Employee::Delete",
  // EmployeeImport = "Employee::Import",
  HumanResourcesEmployeesAdd = 'HumanResources::Employees::Add',
  HumanResourcesEmployeesDelete = 'HumanResources::Employees::Delete',
  HumanResourcesEmployeesModify = 'HumanResources::Employees::Modify',
  HumanResourcesEmployeesReports = 'HumanResources::Employees::Reports',
  HumanResourcesEmployeesView = 'HumanResources::Employees::View',

  MigrateInspectionsView = 'InspectionsandAudits::MigrateInspections::View',
  MigrateInspectionsAdd = 'InspectionsandAudits::MigrateInspections::Add',
  MigrateInspectionsDelete = 'InspectionsandAudits::MigrateInspections::Delete',
  MigrateInspectionsModify = 'InspectionsandAudits::MigrateInspections::Modify',

  InspectionsNewView = 'InspectionsandAudits::InspectionsNew::View',
  InspectionsNewAdd = 'InspectionsandAudits::InspectionsNew::Add',
  InspectionsNewDelete = 'InspectionsandAudits::InspectionsNew::Delete',
  InspectionsNewModify = 'InspectionsandAudits::InspectionsNew::Modify',

  InspectionsProView = 'InspectionsandAudits::InspectionsPro::View',
  InspectionsProAdd = 'InspectionsandAudits::InspectionsPro::Add',
  InspectionsProDelete = 'InspectionsandAudits::InspectionsPro::Delete',
  InspectionsProModify = 'InspectionsandAudits::InspectionsPro::Modify',

  InspectionsPermitView = 'InspectionsandAudits::Permit::View',
  InspectionsPermitAdd = 'InspectionsandAudits::Permit::Add',
  InspectionsPermitDelete = 'InspectionsandAudits::Permit::Delete',
  InspectionsPermitModify = 'InspectionsandAudits::Permit::Modify',

  InspectionsEvaluationView = 'InspectionsandAudits::Evaluation::View',
  InspectionsEvaluationAdd = 'InspectionsandAudits::Evaluation::Add',
  InspectionsEvaluationDelete = 'InspectionsandAudits::Evaluation::Delete',
  InspectionsEvaluationModify = 'InspectionsandAudits::Evaluation::Modify',

  // EmployeeUserList = "Employee::UserList",
  // //EmployeeUserAdd = "Employee::UserAdd",
  // EmployeeUserEdit = "Employee::UserEdit",
  // //EmployeeUserDelete = "Employee::UserDelete",
  // EmployeeAddUserAddEmployee = "Employee::AddUserAddEmployee",
  // EmployeeAddUserLinkEmployee = "Employee::AddUserLinkEmployee",
  // EmployeeInviteUser = "Employee::InviteUser",
  // EmployeeAssignPortal = "Employee::AssignPortal",
  // EmployeeAssignRole = "Employee::AssignRole",
  // EmployeeRemoveUserLogin = "Employee::RemoveUserLogin",
  UserList = "User::List",
  UserDetails = "User::Details",
  UserAdd = "User::Add",
  UserEdit = "User::Edit",
  UserDelete = "User::Delete",
  UserAssignPortal = "User::AssignBusinessUnit",
  UserAssignRole = "User::AssignPermissionGroup",
  UserImport = "User::Import",

  // UserAddView = "User::AddView",
  UserAddEmployee = "User::AddEmployee",
  // UserLinkEmployee = "User::LinkEmployee",
  RoleList = "Role::List",
  RoleAdd = "Role::Add",
  RoleEdit = "Role::Edit",
  RoleDelete = "Role::Delete",

  PortalList = "Portal::List",
  PortalAdd = "Portal::Add",
  PortalEdit = "Portal::Edit",
  PortalDelete = "Portal::Delete",

  AdministrationAreasView = "Administration::Areas::View",
  AdministrationAreasAdd = "Administration::Areas::Add",
  AdministrationAreasModify = "Administration::Areas::Modify",
  AdministrationAreasDelete = "Administration::Areas::Delete",

  AdministrationDepartmentsView = "Administration::Departments::View",
  AdministrationDepartmentsAdd = "Administration::Departments::Add",
  AdministrationDepartmentsModify = "Administration::Departments::Modify",
  AdministrationDepartmentsDelete = "Administration::Departments::Delete",

  AdministrationLocationsView = "Administration::Locations::View",
  AdministrationLocationsAdd = "Administration::Locations::Add",
  AdministrationLocationsModify = "Administration::Locations::Modify",
  AdministrationLocationsDelete = "Administration::Locations::Delete",

}

