import { atomFamily } from "recoil";
import { msalInstance } from "../..";
import { RAFCustomFilter, RAFCustomOperator } from "../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showSuccessToast, showWarningToast } from "../../RAFComponents/Utility/RAFToastComponent";
import { ContentType, SecureAppAdministrator, SecureSuperAdministrator } from "../../RAFComponents/helpers/Constants";
import { hasPermission } from "../../RAFComponents/helpers/PermissionHelper";
import { IsNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../RAFComponents/models/Common/ListRequest";
import { LoginUserRow } from "../../RAFComponents/models/Common/LoginUserRow";
import { UserInfoRow } from "../../RAFComponents/models/Common/UserInfoRow";
import { LookUpRow } from "../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { RoleRow } from "../../RAFModules/Common/Role/RoleRow";
import { EmpLoginStatusText, EmpLoginStatusValue, SkyLoginStatus } from "../../helpers/SkyConstants";
import { UserRow } from "./UserRow";

export enum UserDefaultViewName {
    UserVerified = 'user_verified',
    UserUnverified = 'user_unverified'
}

export enum userFilterTabText {
    AllUser = 'All',
    InvitesPending = 'Invites & Pending',
    SecureID = 'SecureID',
    MicrosoftSSO = 'Microsoft SSO',
    //Unverified = 'Unverified',
}

export const atomUserTabFilterState = atomFamily<any, any>({
    key: "userTabFilterState",
    default: `${userFilterTabText.AllUser}`,
});

export const userFilterTabItems = [
    {
        id: userFilterTabText.AllUser,
        text: userFilterTabText.AllUser,
    },
    {
        id: userFilterTabText.SecureID,
        text: userFilterTabText.SecureID,
    },
    {
        id: userFilterTabText.MicrosoftSSO,
        text: userFilterTabText.MicrosoftSSO,
    },
    {
        id: userFilterTabText.InvitesPending,
        text: userFilterTabText.InvitesPending,
    },
    // {
    //     id: userFilterTabText.Unverified,
    //     text: userFilterTabText.Unverified,
    // },
];

export class UpdateUserLoginFlowRow {
    UserNames?: string[];
    LoginFlow?: number;
    TwoFactor?: boolean;
    PermissionGroupUID?: string;
    PermissionGroup?: string;
    PortalUID?: string;
    Portal?: string;
}

export const checkIsEmployeeExistByUserName = (newUserName: string, employeeID: string) => {
    return new Promise<boolean>(async (resolve) => {
        const userRow: UserRow =
            isNotNullAndUndefined(employeeID) ?
                await retrieveUserLoginByEmployeeUID(employeeID) :
                await getUserByUsername(newUserName);
        if (isNotNullAndUndefined(userRow) && isNotNullAndUndefined(userRow.UID)) {
            if (isNotNullAndUndefined(userRow.EmployeeUID)) {
                if (employeeID === userRow.EmployeeUID) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            } else {
                resolve(false);
            }
        } else {
            resolve(false);
        }
    });
};

export const checkIsUserExist = (newUserName: string, userUID: string, employeeUID: string, mode?: 'AddUserLinkEmployee') => {
    return new Promise<boolean>(async (resolve) => {
        const userRow: UserRow =
            isNotNullAndUndefined(employeeUID) ?
                await retrieveUserLoginByEmployeeUID(employeeUID) :
                await getUserByUsername(newUserName);
        if (isNotNullAndUndefined(userRow) && isNotNullAndUndefined(userRow.UID)) {
            if (isNotNullAndUndefined(userUID) && userRow.UID === userUID) {
                resolve(false);
            } else if (isNotNullAndUndefined(userRow.EmployeeUID)) {
                if (employeeUID === userRow.EmployeeUID) {
                    resolve(false);
                } else {
                    showWarningToast("The employee you've chosen already possesses an alternate user login.", 'Warning', 3000);
                    resolve(true);
                }
            } else if (mode === 'AddUserLinkEmployee') {
                resolve(false);
            } else {
                showWarningToast('A user with the name you specified already exists. Specify a different username.', 'Warning', 3000);
                resolve(true);
            }
        } else {
            resolve(false);
        }
    });
};

export const retrieveUserLoginByEmployeeUID = (employeeUID: string) => {
    return new Promise<UserRow>((resolve) => {
        if (isNotNullAndUndefined(employeeUID)) {
            return repositoryActions.postDataAndGetResponse(
                'User/RetrieveLoginByEmployeeUID',
                { "EntityId": employeeUID },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const getUserByUsername = (userName: string) => {
    return new Promise<UserRow>((resolve) => {
        if (isNotNullAndUndefined(userName)) {
            return repositoryActions.postDataAndGetResponse(
                'User/Exists',
                { "UserName": userName },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const ResendEmployeeInvite = (employeeUID: string) => {
    return new Promise<boolean>((resolve, reject) => {
        if (isNotNullAndUndefined(employeeUID)) {
            return repositoryActions.postDataAndGetResponse(
                'User/InviteUser',
                { "EmployeeUID": employeeUID },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.EntityId)) {
                    showSuccessToast('Invite sent successfully');
                    resolve(true);
                } else {
                    showWarningToast("Sorry something went wrong !");
                    resolve(false);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const getUserLoginMethodsUsername = (userName: string) => {
    return new Promise<LoginUserRow[]>((resolve, reject) => {
        if (isNotNullAndUndefined(userName)) {
            return repositoryActions.postDataAndGetResponse(
                'User/LoginMethods',
                { "UserName": userName },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotEmptyArray(response.data.Entities)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const getAllUserByUsername = (userName: string) => {
    return new Promise<UserRow[]>((resolve, reject) => {
        if (!IsNullOrWhiteSpace(userName)) {
            let relatedFilter: RAFCustomFilter = {};
            relatedFilter.Condition = "and";
            relatedFilter.Rules = [];

            let filter1: RAFCustomFilter = {};
            let filterVal1: string[] = [];
            filterVal1.push(userName);
            filter1.Operator = RAFCustomOperator.Equal;
            filter1.Value = filterVal1;
            filter1.Field = propertyOf<UserRow>("UserName");
            relatedFilter.Rules.push(filter1);

            let listServiceRequest = new ListServiceRequest();
            listServiceRequest.CustomFilter = relatedFilter;
            listServiceRequest.Skip = 0;
            listServiceRequest.Take = 0;

            return repositoryActions.postDataAndGetResponse(
                'User/List',
                listServiceRequest,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data) && isNotEmptyArray(response.data.Entities)) {
                    resolve(response.data.Entities);
                }
                else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const SYNCUsers = (userNames: string[]) => {
    return new Promise<boolean>((resolve, reject) => {
        if (isNotEmptyArray(userNames)) {
            repositoryActions
                .postDataAndGetResponse(
                    "User/Sync",
                    { UserNames: userNames },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && response.data === true
                    ) {
                        showSuccessToast("Synced successfully");
                        resolve(true);
                    } else {
                        showWarningToast("Sorry something went wrong !");
                        resolve(false);
                    }
                })
                .catch((error) => error);
        } else {
            resolve(false);
            showWarningToast("Please select alteast one row to Sync user");
        }
    });
};

export const UpdateUserLoginFlow = (userLoginFlowRow: UpdateUserLoginFlowRow) => {
    return new Promise<boolean>((resolve, reject) => {
        if (isNotNullAndUndefined(userLoginFlowRow) && isNotEmptyArray(userLoginFlowRow.UserNames) && isNotNullAndUndefined(userLoginFlowRow.LoginFlow)) {
            repositoryActions
                .postDataAndGetResponse(
                    "User/UpdateLoginFlow",
                    userLoginFlowRow,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && response.data === true
                    ) {
                        showSuccessToast("Updated successfully");
                        resolve(true);
                    } else {
                        showWarningToast("Sorry something went wrong !");
                        resolve(false);
                    }
                })
                .catch((error) => error);
        } else {
            resolve(false);
            showWarningToast("Please select alteast one row to update user status");
        }
    });
};

export const getAllUsersByTenantUID = (tenantUID: string) => {
    return new Promise<LookUpRow[]>((resolve, reject) => {
        if (isNotNullAndUndefined(tenantUID)) {
            repositoryActions
                .postDataAndGetResponse(
                    "User/ListAll",
                    { TenantUID: tenantUID },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)
                    ) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const SyncCentralisedLoginEmployeeByTenantUID = (tenantUID: string) => {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(tenantUID)) {
            repositoryActions
                .postDataAndGetResponse(
                    "Migration/SyncCentralisedLogin",
                    { TenantUID: tenantUID },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) && response.status === 200
                    ) {
                        showSuccessToast("Synced successfully");
                        resolve(true);
                    } else {
                        showWarningToast("Sorry something went wrong !");
                        resolve(false);
                    }
                })
                .catch((error) => resolve(false));
        } else {
            showWarningToast("Please select alteast one Tenant to Sync Employee");
            resolve(false);
        }
    });
};

export const configureTenantByTenantUID = (tenantUID: string) => {
    return new Promise<boolean>((resolve, reject) => {
        if (isNotNullAndUndefined(tenantUID)) {
            repositoryActions
                .postDataAndGetResponse(
                    "Tenant/Configure",
                    { EntityId: tenantUID },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && response.data === true
                    ) {
                        showSuccessToast("Synced successfully");
                        resolve(true);
                    } else {
                        showWarningToast("Sorry something went wrong !");
                        resolve(false);
                    }
                })
                .catch((error) => error);
        } else {
            showWarningToast("Please select alteast one Tenant to Sync Employee");
            resolve(false);
        }
    });
};

export const CanImpersonateUser = () => {
    return new Promise<boolean>((resolve, reject) => {
        repositoryActions.postDataAndGetResponse('User/CanImpersonateUser', null, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response.data)) {
                    if (response.data === true) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
                else {
                    resolve(false);
                }
            }).catch((error) => error);
    });
};

export const getLoginMethodDisplayValue = (userRow) => {
    let loginMethod;
    if (isNotNullAndUndefined(userRow)) {
        const loginStatus = userRow.LoginFlow;
        const twoFactor = userRow.TwoFactor;

        if (loginStatus === EmpLoginStatusValue.Unverified) {
            loginMethod = EmpLoginStatusText.Unverified;
        } else if (loginStatus === EmpLoginStatusValue.SecureIDPending) {
            if (twoFactor === true) {
                loginMethod = EmpLoginStatusText.SecureID2FAPending;
            } else {
                loginMethod = EmpLoginStatusText.SecureIDPending;
            }
        } else if (loginStatus === EmpLoginStatusValue.SecureIDVerified) {
            if (twoFactor === true) {
                loginMethod = EmpLoginStatusText.SecureID2FAVerified;
            } else {
                loginMethod = EmpLoginStatusText.SecureIDVerified;
            }
        } else if (loginStatus === EmpLoginStatusValue.MSSSOPending) {
            loginMethod = EmpLoginStatusText.MSSSOPending;
        } else if (loginStatus === EmpLoginStatusValue.MSSSOActivated) {
            loginMethod = EmpLoginStatusText.MSSSOActivated;
        } else if (loginStatus === EmpLoginStatusValue.NewuserPending) {
            loginMethod = EmpLoginStatusText.NewuserPending;
        } else {
            loginMethod = EmpLoginStatusText.Unverified;
        }
    }
    return loginMethod;
};


export const RemoveUserLoginMethodByUserID = (
    recordIds: any,
) => {
    return new Promise<Boolean>((resolve) => {
        if (isNotNullAndUndefined(recordIds)) {
            return repositoryActions
                .postDataAndGetResponse(
                    'User/DeleteLoginMethod',
                    { EntityId: recordIds },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (isNotNullAndUndefined(response) && response.data === true) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const checkEmployeeLoginExistsGetUser = (employeeID: string, userName: string) => {
    return new Promise<UserRow>(async (resolve) => {
        const isLoginExists = await checkEmployeeLoginExistsbyEmployeeUID(employeeID);
        if (isLoginExists === true) {
            const userRow: UserRow =
                isNotNullAndUndefined(employeeID) ?
                    await retrieveUserLoginByEmployeeUID(employeeID) :
                    await getUserByUsername(userName);
            resolve(userRow);
        } else {
            resolve(null);
        }
    });
};

export const checkEmployeeLoginExistsbyEmployeeUID = (
    recordId: string,
) => {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(recordId)) {
            return repositoryActions
                .postDataAndGetResponse(
                    'Employee/LoginExists',
                    { EntityId: recordId },
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (isNotNullAndUndefined(response) && response.data === true) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => error);
        } else {
            resolve(false);
        }
    });
};

export enum UserActions {
    AddUser = 'adduser',
    //AddUserCreateEmployee = 'addusercreateemployee',
    //AddUserLinkEmployee = 'adduserlinkemployee',
}

export const hasPermissionToModifySelectedUser = (userRow: UserRow, rolePermissionRow: RoleRow, permissionName: string) => {
    const currentUser: UserInfoRow = msalInstance.currentUser;
    const currentUserRole = isNotNullAndUndefined(currentUser) ? currentUser.PermissionGroup : null;
    const selectedUserRole = isNotNullAndUndefined(userRow) ? userRow.PermissionGroup : null;
    let allowMofify = false;
    if (currentUserRole === SecureAppAdministrator) {
        allowMofify = true;
    } else if (currentUserRole === SecureSuperAdministrator && selectedUserRole !== SecureAppAdministrator) {
        allowMofify = true;
    } else if (selectedUserRole !== SecureSuperAdministrator && selectedUserRole !== SecureAppAdministrator) {
        allowMofify = true;
    } else if (selectedUserRole === currentUserRole) {
        allowMofify = true;
    } else {
        allowMofify = false;
    }
    if (allowMofify && hasPermission(rolePermissionRow, permissionName)) {
        return true;
    } else {
        return false;
    }
};

export const getPermissionGroupLookUpCustomFilter = () => {
    const currentUser: UserInfoRow = msalInstance.currentUser;
    const currentUserRole = isNotNullAndUndefined(currentUser) ? currentUser.PermissionGroup : null;

    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (currentUserRole === SecureAppAdministrator) {
        customFilter = null;
    } else if (currentUserRole === SecureSuperAdministrator) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(SecureAppAdministrator);
        filter.Operator = RAFCustomOperator.NotEqual;
        filter.Value = filterVal;
        filter.Field = propertyOf<RoleRow>("PermissionGroupName");
        customFilter.Rules.push(filter);
    } else {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(SecureAppAdministrator);
        filter.Operator = RAFCustomOperator.NotEqual;
        filter.Value = filterVal;
        filter.Field = propertyOf<RoleRow>("PermissionGroupName");
        customFilter.Rules.push(filter);

        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(SecureSuperAdministrator);
        filter1.Operator = RAFCustomOperator.NotEqual;
        filter1.Value = filterVal1;
        filter1.Field = propertyOf<RoleRow>("PermissionGroupName");
        customFilter.Rules.push(filter1);
    }

    return customFilter;
};

export const getLoginMethodStatusTwoFactorColorCode = (userRow: UserRow, employeeLoginStatus: string) => {
    let loginStatusValue;
    let loginFlowValue;//loginMethod

    let twoFactor: boolean = false;
    if (isNotNullAndUndefined(userRow)) {
        loginStatusValue = userRow.LoginStatus ?? employeeLoginStatus;
        twoFactor = userRow.TwoFactor;
        const loginFlow = userRow.LoginFlow;
        if (
            loginFlow === EmpLoginStatusValue.SecureIDPending  //1
            || loginFlow === EmpLoginStatusValue.SecureIDVerified || //2 
            loginFlow === EmpLoginStatusValue.NewuserPending //5
        ) {
            loginFlowValue = userFilterTabText.SecureID;
        } else if (
            loginFlow === EmpLoginStatusValue.MSSSOPending || //3
            loginFlow === EmpLoginStatusValue.MSSSOActivated //4
        ) {
            loginFlowValue = userFilterTabText.MicrosoftSSO;
        } else if (employeeLoginStatus === SkyLoginStatus.InActive) {
            loginFlowValue = SkyLoginStatus.InActive;
        }
        else {
            loginFlowValue = 'Add Login';
        }
    }

    let loginStatusColorCode = '#999';
    if (loginStatusValue === SkyLoginStatus.Active) {
        loginStatusColorCode = 'green';
    } else if (
        loginStatusValue === SkyLoginStatus.InviteSent ||
        loginStatusValue === SkyLoginStatus.PendingInvite ||
        loginStatusValue === SkyLoginStatus.PendingLogin
    ) {
        loginStatusColorCode = 'orange';
    } else if (loginStatusValue === SkyLoginStatus.InActive) {
        loginStatusColorCode = 'red';
    }

    const response = {
        "loginStatusColorCode": loginStatusColorCode,
        "loginFlowValue": loginFlowValue,
        "loginStatusValue": loginStatusValue,
        "twoFactor": twoFactor
    };

    return response;
};

export const getLoginMethodStatusTwoFactorColorCode1 = (userRow: UserRow, employeeLoginStatus: string) => {
    let loginStatusColorCode;
    let loginMethod;
    let loginStatus;
    let loginStatusValue;
    let twoFactor: boolean = false;
    if (isNotNullAndUndefined(userRow)) {
        loginStatus = userRow.LoginFlow;
        twoFactor = userRow.TwoFactor;

        if (loginStatus === EmpLoginStatusValue.Unverified) {
            loginStatusValue = 'Add Login';
            // loginMethod = EmpLoginStatusText.Unverified;
        }
        else if (loginStatus === EmpLoginStatusValue.SecureIDPending) {
            loginStatusValue = SkyLoginStatus.InviteSent;
            loginStatusColorCode = 'orange';
            // if (twoFactor === true) {
            //     loginMethod = EmpLoginStatusText.SecureID2FAPending;
            // } else {
            //     loginMethod = EmpLoginStatusText.SecureIDPending;
            // }
        }
        else if (loginStatus === EmpLoginStatusValue.SecureIDVerified) {
            loginStatusValue = SkyLoginStatus.Active;
            loginStatusColorCode = 'green';
            if (twoFactor === true) {
                loginMethod = EmpLoginStatusText.SecureID2FAVerified;
            } else {
                loginMethod = EmpLoginStatusText.SecureIDVerified;
            }
        }
        else if (loginStatus === EmpLoginStatusValue.MSSSOPending) {
            loginStatusValue = SkyLoginStatus.InviteSent;
            loginStatusColorCode = 'orange';
            // loginMethod = EmpLoginStatusText.MSSSOPending;
        }
        else if (loginStatus === EmpLoginStatusValue.MSSSOActivated) {
            loginStatusValue = SkyLoginStatus.Active;
            loginMethod = EmpLoginStatusText.MSSSOActivated;
            loginStatusColorCode = 'green';
        }
        else if (loginStatus === EmpLoginStatusValue.NewuserPending) {
            loginStatusValue = SkyLoginStatus.InviteSent;
            loginStatusColorCode = 'orange';
            // loginMethod = EmpLoginStatusText.NewuserPending;
        }
        else {
            loginStatusValue = 'Add Login';
            loginStatusColorCode = '#999';
            // loginMethod = EmpLoginStatusText.Unverified;
        }
        if (employeeLoginStatus === SkyLoginStatus.InActive) {
            loginStatusValue = SkyLoginStatus.InActive;
            loginStatusColorCode = 'red';
        }
    }
    else {
        loginStatusValue = 'Add Login';
        loginStatusColorCode = '#999';
    }

    const response = {
        "loginStatusColorCode": loginStatusColorCode,
        "loginMethod": loginMethod,
        "loginStatus": loginStatus,
        "loginStatusValue": loginStatusValue,
        "twoFactor": twoFactor
    };

    return response;
};