import { MappingPair, MapperConfiguration } from '@dynamic-mapper/mapper';
import { LogInAsRequestRow, ILogInAsRequestDto } from "./../Models/LogInAsRequestRow";

export const ConvertLogInAsRequestRowToLogInAsRequestDto = (LogInAsRequestRow: LogInAsRequestRow): ILogInAsRequestDto => {

    const LogInAsRequestRowToILogInAsRequestDto = new MappingPair<LogInAsRequestRow, ILogInAsRequestDto>();
    const LogInAsRequestRowToLogInAsRequestDtoConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(LogInAsRequestRowToILogInAsRequestDto, {})
            .forSourceMember("Employee", opt => opt.ignore())
            .forSourceMember("Tenant", opt => opt.ignore())
            ;
    });

    const mapper = LogInAsRequestRowToLogInAsRequestDtoConfiguration.createMapper();
    return mapper.map(LogInAsRequestRowToILogInAsRequestDto, LogInAsRequestRow);
}

