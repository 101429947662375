import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from 'react';
import { msalInstance } from '../../..';
import { LoginUserContext } from '../../../RAFAuthentication/RAFLoginUserContextProvider';
import { RolePermissionsContext } from '../../../RAFAuthentication/RAFRolePermissionsContextProvider';
import { getSessionStorage, hideProgress, removeSessionStorage, setSessionStorage, showProgress } from '../../../RAFComponents/helpers/AppHelper';
import { Constants, RAFReportType, StorageKey } from '../../../RAFComponents/helpers/Constants';
import { MenuCategory } from '../../../RAFComponents/helpers/RAFMenuHelper';
import { IsNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined, isNullOrUndefined, wrapChildrenWith } from '../../../RAFComponents/helpers/utils';
import { UserInfoRow } from '../../../RAFComponents/models/Common/UserInfoRow';
import { RMStorageKey } from '../../../RAFMaster/helpers/RMConstants';
import { WorkspaceRow } from '../../Common/Workspace/WorkspaceRow';
import { getWorkspaceList } from '../../Common/Workspace/WorkspaceService/WorkspaceServiceHelper';
import { getDefaultActiveCurrentViewFromList, getViewsReportsDashboards } from '../List/IndexHelper';
import { getAllPermissions } from '../Role/RoleHelper';
import { RAFPortalType, defaultPortalItems, defaultPortalName } from '../Workspace/WorkspaceHelper';
import { PortalTreeViewItemRow, getLeftNavigationMenuItems } from '../Workspace/WorkspaceItem/WorkspaceItemHelper';

const WithLoginUserContext = (Component) => {
    return (props) => (
        <LoginUserContext.Consumer>
            {({ userInfo }) => <Component {...props} userInfo={userInfo} />}
        </LoginUserContext.Consumer>
    );
};

interface IState {
    isLoading: boolean;
    workspaceList?: WorkspaceRow[],
    currentWorkspace: WorkspaceRow;
    portalTreeViewItems: PortalTreeViewItemRow[];
    currentMenuCategory: MenuCategory;
}

interface IProps {//extends RouteComponentProps {
    userInfo?: UserInfoRow,
}

export const WorkspaceListContext = React.createContext({ workspaceList: [] as WorkspaceRow[], updateWorkspaceList: null });
export const CurrentWorkspaceContext = React.createContext({ currentWorkspace: null as WorkspaceRow, setCurrentWorkspace: null });
export const WorkspaceLeftItemsContext = React.createContext({ portalTreeViewItems: [] as PortalTreeViewItemRow[], currentMenuCategory: null as MenuCategory, updateCurrentWorkspaceLeftMenuItems: null });

function WorkspaceContextProvider({ ...props }: PropsWithChildren<IProps>) {

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            workspaceList: null,
            currentWorkspace: null,
            portalTreeViewItems: null,
            currentMenuCategory: null,
        }
    );

    useEffect(() => {
        initWorkspace();
    }, [props.userInfo]);

    const permissionContext = useContext(RolePermissionsContext);
    const permissionValue = isNotNullAndUndefined(permissionContext) ? permissionContext.permissionValue : null;


    const initWorkspace = async () => {
        if (isNotNullAndUndefined(props.userInfo) && !IsNullOrWhiteSpace(props.userInfo.UserUID)) {
            setState({ isLoading: true });
            //let progressDiv = showProgress('body', true);
            const [allPermissions, workspaceList] = await Promise.all([getAllPermissions(), getWorkspaceList()]);
            const recentClickedWorkspace: WorkspaceRow = JSON.parse(getSessionStorage(StorageKey.CurrentPortal, true));

            const isDefaultItem = isNotNullAndUndefined(recentClickedWorkspace) && isNullOrUndefined(recentClickedWorkspace.UID) && recentClickedWorkspace.PortalName === defaultPortalName ? true : false;

            if (isNotEmptyArray(workspaceList) && isDefaultItem === false) {
                let currentWorkspace: WorkspaceRow;
                currentWorkspace = isNotNullAndUndefined(recentClickedWorkspace) && isNotNullAndUndefined(recentClickedWorkspace.UID) ?
                    workspaceList.find(x => x.UID === recentClickedWorkspace.UID) : null;

                if (isNullOrUndefined(currentWorkspace)) {
                    const tenantPortal = workspaceList.find(x => x.PortalType === RAFPortalType.Tenant);
                    let defaultPortal;
                    if (isNotNullAndUndefined(msalInstance) && isNotNullAndUndefined(msalInstance.currentBusinessUnitId)) {
                        defaultPortal = workspaceList.find(x =>
                            x.UID === msalInstance.currentBusinessUnitId
                        );
                    } else if (isNotNullAndUndefined(tenantPortal) && isNotNullAndUndefined(tenantPortal.UID)) {
                        defaultPortal = tenantPortal;
                    } else {
                        defaultPortal = workspaceList.find(x =>
                            isNotNullAndUndefined(x.PortalName) && x.PortalName.toLowerCase() === Constants.MyOrganizationPortal
                        );
                    }

                    currentWorkspace = isNotNullAndUndefined(defaultPortal) && isNotNullAndUndefined(defaultPortal.UID) ? defaultPortal : workspaceList[0];
                }

                if (isNotNullAndUndefined(currentWorkspace) && isNotNullAndUndefined(currentWorkspace.UID)) {
                    setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(currentWorkspace));
                    const portalTreeViewItems = getLeftNavigationMenuItems(currentWorkspace, null, permissionValue);
                    const currentMenuCategory = getSessionStorage(
                        RMStorageKey.currentMenuCategory,
                        false
                    );
                    setState({ isLoading: false, workspaceList, currentWorkspace, portalTreeViewItems, currentMenuCategory });
                } else {
                    // hideProgress(progressDiv);
                    setState({ isLoading: false, workspaceList, currentWorkspace: null });
                }
            } else {
                let defaultItem = isNotNullAndUndefined(recentClickedWorkspace) && isNotNullAndUndefined(recentClickedWorkspace.PortalName) ? defaultPortalItems.find(x => x.PortalName === recentClickedWorkspace.PortalName) : defaultPortalItems[0];
                setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(defaultItem));
                const portalTreeViewItems = getLeftNavigationMenuItems(defaultItem, null, permissionValue);
                const currentMenuCategory = getSessionStorage(
                    RMStorageKey.currentMenuCategory,
                    false
                );
                setState({ isLoading: false, workspaceList: workspaceList, currentWorkspace: defaultItem, portalTreeViewItems, currentMenuCategory });
            }
        } else {
            setState({ isLoading: false });
        }
    };

    const updateWorkspaceList = (workspaceUID?: string) => {
        removeSessionStorage(StorageKey.PortalList, true);
        let progressDiv = showProgress('body', true);
        getWorkspaceList().then(workspaceList => {
            if (isNotNullAndUndefined(workspaceUID))
                updateCurrentWSSessionStorage(workspaceUID, workspaceList);
            setState({ workspaceList });
            hideProgress(progressDiv);
        });
    };

    const updateCurrentWSSessionStorage = (workspaceUID: string, workspaceList: WorkspaceRow[]) => {
        let { currentWorkspace } = state;
        if (isNotNullAndUndefined(workspaceUID) && isNotNullAndUndefined(currentWorkspace)) {
            if (currentWorkspace.UID === workspaceUID) {
                let workspaceRow = isNotNullAndUndefined(workspaceList) && workspaceList.length > 0 ? workspaceList.find(x => x.UID === workspaceUID) : null;
                if (isNotNullAndUndefined(workspaceRow))
                    setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(workspaceRow));
            }
        }
    };

    const setCurrentWorkspace = (workspaceRow: WorkspaceRow) => {
        if (isNotNullAndUndefined(workspaceRow)) {
            let progressDiv = showProgress('body', true);
            setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(workspaceRow));
            setState({ currentWorkspace: workspaceRow });
            hideProgress(progressDiv);
            //window.location.href = '/Dashboard';
        }
        else {
            setState({ currentWorkspace: workspaceRow });
        }
    };

    const updateCurrentWorkspaceLeftMenuItems = async (recentViewId?: string, itemEntity?: string, currentWorkspace?: WorkspaceRow) => {
        if (isNotNullAndUndefined(itemEntity) && isNotNullAndUndefined(itemEntity)) {
            const viewMenuDataSource = await getViewsReportsDashboards(
                itemEntity,
                true,
                true
            );
            const currentView = await getDefaultActiveCurrentViewFromList(
                itemEntity,
                recentViewId,
                RAFReportType.Table,
                viewMenuDataSource,
                true,
                permissionValue
            );
        }
        setState({ portalTreeViewItems: null });
        const selectedWorkspace = isNotNullAndUndefined(currentWorkspace) ? currentWorkspace : state.currentWorkspace;
        const portalTreeViewItems = getLeftNavigationMenuItems(selectedWorkspace, null, permissionValue);
        const currentMenuCategory = getSessionStorage(
            RMStorageKey.currentMenuCategory,
            false
        );

        setState({ portalTreeViewItems: portalTreeViewItems, currentMenuCategory });
    };

    return (
        <WorkspaceListContext.Provider
            value={{
                workspaceList: state.workspaceList,
                updateWorkspaceList: updateWorkspaceList,
            }}
        >
            <CurrentWorkspaceContext.Provider
                value={{
                    currentWorkspace: state.currentWorkspace,
                    setCurrentWorkspace: setCurrentWorkspace,
                }}
            >
                <WorkspaceLeftItemsContext.Provider
                    value={{
                        portalTreeViewItems: state.portalTreeViewItems,
                        currentMenuCategory: state.currentMenuCategory,
                        updateCurrentWorkspaceLeftMenuItems: updateCurrentWorkspaceLeftMenuItems
                    }}
                >
                    {wrapChildrenWith(props.children, {})}
                </WorkspaceLeftItemsContext.Provider>
            </CurrentWorkspaceContext.Provider>
        </WorkspaceListContext.Provider>
    );
}

export default WithLoginUserContext(WorkspaceContextProvider);
