import { Browser } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import { DialogComponent, TooltipComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, useContext, useReducer, useRef } from "react";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { Link } from "react-router-dom";
import { msalInstance } from "../../..";
import RAFProfileCard from "../../../helpers/RAFProfileCard/RAFProfileCard";
//import { navigateToDashboard } from "../../../helpers/ACutils";
//import RAFProfileCard from "../../../helpers/RAFProfileCard";
import { getSessionStorage } from "../../../RAFComponents/helpers/AppHelper";
import { RAFEntityName } from "../../../RAFComponents/helpers/Constants";
import { GetDefaultMainNavigationMenuItems, getModuleAvatarIcon } from "../../../RAFComponents/helpers/RAFMenuHelper";
import { ConvertToElementID, Guid, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { MenuRow } from "../../../RAFComponents/models/Common/RAFMenuModel";
import { RMStorageKey } from "../../../RAFMaster/helpers/RMConstants";
import RAFBusinessUnit from "../../../SkytrustModules/BusinessUnit/RAFBusinessUnit";
import { default as SkytrustLogoWhiteShort } from "./../../../images/skytrust_short_logo_edited.png";
import { default as workesioLogo } from "./../../../images/workesio logo reversal.png";
// import { RAFNavigationContext } from "../../../RAFModules/Common/Providers/RAFNavigationContextProvider";
// import { CurrentWorkspaceContext, WorkspaceItemsContext } from "../../../RAFModules/Common/Providers/WorkspaceContextProvider";
// import SwitchWorkspace from "../../../RAFModules/Common/Workspace/SwitchWorkspace";
// import { WorkspaceRow } from "../../../RAFModules/Common/Workspace/WorkspaceRow";
// import { UserFeedsContext } from "../UserFeedsContextProvider";
import "./../../../styles/NavigationMenu.scss";
import "./NavigationMenu.scss";
import "./SkyNavigationMenu.scss";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";


const SkytrustLogoWhite = `${window.location.origin}/Content/img/SkytrustLogoWhite.png`;
const windowWidth = window.innerWidth;

const currentMenuCategory = getSessionStorage(RMStorageKey.currentMenuCategory, false);

const defaultMenuItems: MenuRow[] = [
    {
        UID: Guid.newGuid(),
        Name: "home",
        Text: "Home",
        Url: "/home",
        Icon: getModuleAvatarIcon("home"),
    },
    {
        UID: Guid.newGuid(),
        Name: "task_dashboard",
        Text: "Tasks",
        Url: "/taskdashboard",
        Icon: getModuleAvatarIcon(RAFEntityName.Task),
    }
];

interface IProps {
    //addDefaultMenuItems?: boolean;
}

interface IState {
    showWorkspaceContent: boolean;
    showMoreMenuContainer: boolean;
}

function RAFLeftNavigationMenu({ ...props }: PropsWithChildren<IProps>) {

    const listViewObj = useRef<ListViewComponent>(null);
    const sidebarobj = useRef<SidebarComponent>(null);
    const showMoreMenuDialog = useRef<DialogComponent>(null);
    const permissionsContext = useContext(RolePermissionsContext);
    const permissionValue = isNotNullAndUndefined(permissionsContext) ? permissionsContext.permissionValue : null;

    //const currentWorkspaceContext = useContext(CurrentWorkspaceContext);
    //const workspaceItemsContext = useContext(WorkspaceItemsContext);
    //const rolePermissionsContext = useContext(RolePermissionsContext);
    //const currentWorkspace = isNotNullAndUndefined(currentWorkspaceContext) ? currentWorkspaceContext.currentWorkspace : null;
    //const workspaceItems = isNotNullAndUndefined(workspaceItemsContext) ? workspaceItemsContext.workspaceItems : null;


    const listFields = {
        id: "UID",
        text: "Text",
        //htmlAttributes: "htmlAttributes",
    };

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            showWorkspaceContent: false,
            showMoreMenuContainer: false,
        }
    );

    // useEffect(() => {
    //     //refresh();
    // }, [currentWorkspace, workspaceItems]);

    //workspace_Content start
    const WorkspaceModalToggle = () => {
        let secondary_left_Menu = document.getElementById('secondary_left_Menu');
        if (isNotNullAndUndefined(secondary_left_Menu)) {
            setState({ showWorkspaceContent: !state.showWorkspaceContent });
        } else {
            setState({ showWorkspaceContent: false });
        }
    };

    const workspaceDialogClose = () => {
        setState({ showWorkspaceContent: false });
    };

    //workspace_Content end

    const onClickLeftMenuItem = (itemUID: string, leftMenuData: MenuRow[], showListSidebarRight?: 'hide') => {
        // const selectedMenuItem = isNotNullAndUndefined(itemUID) &&
        //     isNotNullAndUndefined(leftMenuData) ? leftMenuData.find(x => x.UID === itemUID) : null;
        // if (isNotNullAndUndefined(updateSelectedMenuItem) && isNotNullAndUndefined(selectedMenuItem)) {
        //     updateSelectedMenuItem(selectedMenuItem);
        // }
        let listSidebarRight = document.getElementById("listSidebar_right");
        if (showListSidebarRight === 'hide') {
            if (isNotNullAndUndefined(listSidebarRight)) {
                listSidebarRight.classList.add("hidden");
            }
        }
        if (state.showWorkspaceContent === true) {
            setState({ showWorkspaceContent: false });
        }
    };

    function listTemplate(leftMenuData: MenuRow[], flexRowTemplate: boolean, item: MenuRow) {

        return (
            <Link
                to={item.Url}
                id={ConvertToElementID("sidebarlist" + item.Name)}
                onClick={(e) => {
                    let isSelectedMenuItem = false;
                    if (isNotNullAndUndefined(item.Url) && item.Url.toLowerCase() === window.location.pathname.toLowerCase()) {
                        isSelectedMenuItem = true;
                    }
                    if (!isSelectedMenuItem && isNotNullAndUndefined(item.Url)) {
                        onClickLeftMenuItem(item.UID, leftMenuData, 'hide');
                    } else {
                        e.preventDefault();
                    }
                }}
            >
                {flexRowTemplate ?
                    <div
                        className="menu-item-text d-flex"
                    >
                        <div className="d-flex align-items-center position-relative">
                            {isNotNullAndUndefined(item) &&
                                isNotNullAndUndefined(item.isExternalIcon) &&
                                item.isExternalIcon === true ? (
                                <IconPickerItem
                                    icon={
                                        isNotNullAndUndefined(item) &&
                                            isNotNullAndUndefined(item.Icon)
                                            ? (item.Icon as IconList)
                                            : "FaBriefcase"
                                    }
                                    size={15}
                                    containerStyles={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#fff"
                                    }}
                                    color="#fff"
                                />
                            ) : (
                                <i
                                    className={"text-white fa fa-" + item.Icon}
                                ></i>
                            )}
                        </div>
                        <div className="e-sidebar__list-item-text ecllipseFirstLine">{item.Text}</div>

                        <i className="e-sidebar__thumbtack fad fa-thumbtack ms-auto"></i>

                    </div>
                    :
                    <TooltipComponent
                        // container={"#listSidebarContainer"}
                        content={item.Text}
                        position={"RightCenter"}
                        opensOn="Hover"
                    >
                        <div
                            className="menu-item-text flex-column"
                        >
                            <div className="d-flex align-items-center position-relative">
                                {/* <span
                                className={
                                    item.Name === "Home" ? "transparent-avatar avatar-text-square"
                                        :
                                        getModuleAvatarClass(item.Name) + " avatar-text-square"
                                }
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    minWidth: "24px",
                                    maxWidth: "24px",
                                    fontSize: "1em",
                                    fontWeight: 600,
                                    //marginRight: "0.625em",
                                }}
                            > */}
                                {isNotNullAndUndefined(item) &&
                                    isNotNullAndUndefined(item.isExternalIcon) &&
                                    item.isExternalIcon === true ? (
                                    <IconPickerItem
                                        icon={
                                            isNotNullAndUndefined(item) &&
                                                isNotNullAndUndefined(item.Icon)
                                                ? (item.Icon as IconList)
                                                : "FaBriefcase"
                                        }
                                        size={15}
                                        containerStyles={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "#fff"
                                        }}
                                        color="#fff"
                                    />
                                ) : (
                                    <i
                                        className={"text-white fa fa-" + item.Icon}
                                    ></i>
                                )}
                                {/* </span> */}
                            </div>
                            <div className="e-sidebar__list-item-text ecllipseFirstLine">{item.Text}</div>
                        </div>
                    </TooltipComponent>
                }
            </Link>
        );
    }

    const toggleMoremenu = (toggle?: 'show' | "hide") => {
        // let moreMenuContainer = document.getElementById("moreMenuContainer")
        if (toggle === 'show') {
            setState({ showMoreMenuContainer: true });
            setTimeout(() => {
                showMoreMenuDialog.current.show();
            }, 100);

            // moreMenuContainer.classList.remove("hidden")
        }
        else {
            showMoreMenuDialog.current.hide();
            setTimeout(() => {
                setState({ showMoreMenuContainer: false });
            }, 100);
            // setState({ showMoreMenuContainer: false });
            // moreMenuContainer.classList.add("hidden")
        }
    };

    const moreMenuContent = () => {
        return (
            <div className={"h-100 e-sidebar__moremenu"}>
                <div className="brandLogoDiv d-flex justify-content-center">
                    <img
                        src={SkytrustLogoWhite}
                        alt="Product Logo"
                        className="prodLogoImage"
                    />
                </div>
                <div className="profileDiv e-sidebar__workspace">
                    <div className="w-100">
                        <div className="d-flex align-items-center justify-content-center" onClick={() => toggleMoremenu('hide')}>
                            <span>
                                <i className="fass fa-arrow-left-long pe-2"></i>
                            </span>
                            <span >Back to Start Menu</span>
                        </div>
                    </div>
                </div>
                <div className="listSidebarListContainer customScrollBar mt-2"
                >
                    <ListViewComponent
                        ref={listViewObj}
                        id="listSidebarList"
                        dataSource={leftMenuData as any}
                        cssClass="e-template-list customScrollBar e-sidebar__moremenu__list"
                        template={listTemplate.bind(this, leftMenuData, true)}
                        fields={listFields}
                        showHeader={false}
                    ></ListViewComponent>
                </div>
            </div>
        );
    };

    const getLeftMenuItems = () => {
        const leftMenuData: MenuRow[] = GetDefaultMainNavigationMenuItems(permissionValue);

        return leftMenuData;
    };

    const leftMenuData: MenuRow[] = getLeftMenuItems();
    const currentBusinessUnitId = msalInstance.currentBusinessUnitId;

    return (
        <div className="h-100" id="leftnavigationmenudiv">
            <div className="h-100" id="listSidebarContainer">
                <SidebarComponent
                    id="listSidebar"
                    ref={sidebarobj}
                    className={
                        // windowWidth >= 767
                        Browser.isDevice
                            ? "sidebar-nav e-sidebar border-0 h-100 customScrollBar isNotDevice"
                            : "sidebar-nav e-sidebar border-0 h-100 customScrollBar"
                    }
                    target={'#root'}
                    // enableDock={windowWidth < 767 ? false : true}
                    // type="Auto"
                    // width={260}
                    isOpen={windowWidth < 767 ? false : true}
                    enableGestures={false}
                >
                    <div className="row g-0 h-100">
                        <div className="col-auto h-100">
                            <div id="eSidebarLeft" className="e-sidebar__left">
                                <div className="brandLogoDiv d-flex justify-content-center">
                                    <div className="prodLogoImage_div">
                                        <img
                                            src={SkytrustLogoWhiteShort}
                                            alt="Product Logo"
                                            className="prodLogoImage"
                                        />
                                    </div>
                                </div>
                                <div className="profileDiv e-sidebar__workspace p-0">
                                    <div
                                        id="sidebarlistWorkspace"
                                        onClick={() => toggleMoremenu("show")}
                                    >
                                        {/* <Link
                                                        to={'portal/list'}> */}

                                        <div className="e-sidebar__workspace__div">
                                            <span className="e-sidebar__workspace__icon">
                                                <i className="fas fa-grid"></i>
                                            </span>
                                            <div className="secondary-text-sm text-white text-nowrap">
                                                {/* Portals */}
                                                Show Menu
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div
                                        className="portal-btn-col"
                                        id="sidebarlistWorkspace"
                                        onClick={() => WorkspaceModalToggle()}
                                    >
                                        <div className="e-sidebar__workspace__div">
                                            <span className="e-sidebar__workspace__icon">
                                                <i className="fas fa-grid"></i>
                                            </span>
                                            <div className="secondary-text-sm text-white">
                                                Business Unit
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="listSidebarListContainer customScrollBar mt-1" >
                                    <ListViewComponent
                                        ref={listViewObj}
                                        id="listSidebarList"
                                        dataSource={leftMenuData as any}
                                        cssClass="e-template-list customScrollBar"
                                        template={listTemplate.bind(this, leftMenuData, false)}
                                        fields={listFields}
                                        showHeader={false}
                                    ></ListViewComponent>
                                </div>
                                <div className="e-sidebar__profileCardDiv">
                                    <RAFProfileCard arrowTipClass="bottom-right-tip" hasPermissionToViewAdministration></RAFProfileCard>
                                </div>
                            </div>
                        </div>
                        <div className={state.showWorkspaceContent ? "col e-sidebar__right__col__portals" : "hidden"} id="e-sidebar_right_col_portals">
                            <div className="brandLogoDiv d-flex justify-content-center px-2">
                                <img
                                    src={workesioLogo}
                                    alt="Product Logo"
                                    className="prodLogoImage"
                                />
                            </div>
                            <div className="profileDiv">
                                <div className="d-flex align-items-center w-100">
                                    <ButtonComponent type="button" iconCss="fass fa-arrow-left-long text-white" className="link-button" onClick={() => workspaceDialogClose()}></ButtonComponent>
                                    <span className="w-100 text-truncate header-text text-white ps-1">
                                        Business Unit
                                    </span>
                                </div>
                            </div>
                            <div className="h-100 overflow-auto">
                                <RAFBusinessUnit
                                    businessUnit={currentBusinessUnitId}
                                    enable={true}
                                    onClose={() => this.businessUnitDialogClose("divBusinessUnitDialog")}
                                    cssClass="e-sidebar__right__treeview"
                                />
                                {/* <SwitchWorkspace
                                    onClose={() => workspaceDialogClose()}
                                    {...props}
                                /> */}
                            </div>
                        </div>
                        <div className={!state.showWorkspaceContent ? "col e-sidebar__right__col" : "hidden"} id="e-sidebar_right_col">

                        </div>
                    </div>

                    {
                        state.showMoreMenuContainer &&
                        <DialogComponent
                            visible={false}
                            ref={showMoreMenuDialog}
                            cssClass="rightDialog leftDialog e-sidebar__dialog"
                            content={moreMenuContent.bind(this)}
                            isModal
                            target="body"
                            id="moremenuContentDialog"
                            close={toggleMoremenu}
                            overlayClick={toggleMoremenu}
                        ></DialogComponent>
                    }
                </SidebarComponent>
            </div>
        </div>
    );

}
export default React.memo(RAFLeftNavigationMenu);