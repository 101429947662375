import React from "react";
import { useNavigate } from "react-router-dom";

const SkytrustLogoWhite = `${window.location.origin}/Content/img/SkytrustLogoWhite.png`;

function NavigationMenuProductLogo() {
    let navigate = useNavigate();

    const onDoubleClickProductLogo = () => {
        navigate("/switchtenant");
    };

    return (
        <div className="brandLogoDiv d-flex justify-content-center">
            <img
                src={SkytrustLogoWhite}
                alt="Product Logo"
                className="e-sidebar__treeview__logo cursor"
                onDoubleClick={onDoubleClickProductLogo}
            />
            {/* <ButtonComponent
          type="button"
          iconCss="fas fa-angles-left"
          cssClass="custom-button-sm primary-custom-button text-white ms-2"
          style={{ borderColor: "#04a9f4" }}
          onClick={() => toggleclick()}
        ></ButtonComponent> */}
        </div>
    );
}

export default React.memo(NavigationMenuProductLogo);