import { Fragment } from 'react';
import { RAFDefaultFieldProps } from '../../../RAFComponents/Inputs/RFFUtils';
import { InputFieldColclassname } from '../../../RAFComponents/helpers/Constants';
import { isNotEmptyArray, isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';

//const RAFTextBox = ({ field, label, required }) => {

function SkyCustomTextBox(
    props: { label: string, required?: boolean, showLabel?: boolean, width?: string, inputFieldrowClassName?: string, rightDataClassName?: string, children: any, customchildrenWithCol?: boolean, skyFormFieldRowClassName?: string, allowFullWidth?: boolean; }
) {

    let inputFieldClassName;
    if (props.allowFullWidth) {
        inputFieldClassName = `${InputFieldColclassname.FullWidth} rightData`;
    }
    else {
        inputFieldClassName = `${InputFieldColclassname.HalfWidth} rightData`;
    }

    return (
        <Fragment>
            <div className={isNotNullAndUndefined(props.skyFormFieldRowClassName) ? `${props.skyFormFieldRowClassName} row skyFormField` : "row skyFormField g-0"}>
                <div className="col-md-5 col-xl-3 col-sm-3 leftLabel">
                    {props.showLabel && props.showLabel === true &&
                        <label className={(props.required ? "form-label required" : "form-label")}>{props.label} </label>
                    }
                </div>
                <div className={`${props.rightDataClassName ? props.rightDataClassName : ""} ${inputFieldClassName}`}>
                    <div style={{ width: props.width }}>
                        <div className={props.inputFieldrowClassName ? `row ${props.inputFieldrowClassName}` : "row g-2"}>
                            {props.customchildrenWithCol === true ?
                                isNotEmptyArray(props.children) ?
                                    props.children.map((item, index) => {
                                        return item;
                                    })
                                    :
                                    props.children
                                :
                                isNotEmptyArray(props.children)
                                    ?
                                    props.children.map((item, index) => {
                                        return <div key={index} className='col-sm-6'>
                                            <div>
                                                {item}
                                            </div>
                                        </div>;
                                    })
                                    :
                                    <div className='col-sm-6'>
                                        <div>
                                            {props.children}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

SkyCustomTextBox.defaultProps = RAFDefaultFieldProps;

export default SkyCustomTextBox;