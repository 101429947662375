import * as repositoryActions from "../../..//RAFComponents/store/actions/repositoryActions";
import { ContentType, RAFReportType } from "../../../RAFComponents/helpers/Constants";
import { isNotEmptyArray, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { getViewsReportsDashboards } from "../List/IndexHelper";
import { PortalTreeViewItemRow } from "./WorkspaceItem/WorkspaceItemHelper";
import { WorkspaceRow } from "./WorkspaceRow";
import { WorkspaceItemRow } from "./WorkspaceService/WorkspaceItemRow";


export const defaultPortalName = "All Portals";

export const defaultPortalItems = [
    {
        UID: null,
        PortalName: defaultPortalName,
        Icon: 'FaAvianex'
    }
] as WorkspaceRow[];


export enum RAFPortalType {
    Tenant = 'Tenant',
    Internal = 'Internal',
    Public = 'Public',
    PartnerHub = 'Partner Hub',
    Project = 'Project',
    PartnerConnect = 'Partner Connect',
}

export const systemPortalNames = ['WorkESIO'];

export function isPortalChildItemExist(selectedWorkspaceUID: string, workspaceList: WorkspaceRow[]): boolean {
    let isExist = false;
    if (isNotEmptyArray(workspaceList) && isNotNullAndUndefined(selectedWorkspaceUID)) {
        const isTenantPortal = workspaceList.find(x => x.UID === selectedWorkspaceUID);
        if (isNotNullAndUndefined(isTenantPortal) && isTenantPortal.PortalType === RAFPortalType.Tenant) {
            isExist = true;
            showWarningToast('You do not have sufficient privileges to delete this item.');
        } else {
            let childItems = workspaceList.find(x => x.ParentUID === selectedWorkspaceUID);
            if (isNotNullAndUndefined(childItems) && isNotNullAndUndefined(childItems.UID)) {
                showWarningToast('Please delete all the sub Portals before deleting this Portal.');
                isExist = true;
            }
        }
    }
    return isExist;
}

export function getAllPortalList(viewUID) {
    return new Promise<WorkspaceRow[]>((resolve, reject) => {
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;
        if (isNotNullAndUndefined(viewUID))
            listServiceRequest.ViewUID = viewUID;
        return repositoryActions.postDataAndGetResponse(
            `Portal/List`,
            listServiceRequest,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
            } else {
                resolve(null);
            }

        })
            .catch((error) => error);
    });
}

// export function getAllPartnetPortalList1() {
//     return new Promise<WorkspaceRow[]>((resolve, reject) => {
//         let listServiceRequest = new ListServiceRequest();
//         listServiceRequest.Skip = 0;
//         listServiceRequest.Take = 0;

//         let customFilter: RAFCustomFilter = {};
//         customFilter.Condition = "and";
//         customFilter.Rules = [];

//         let filter1: RAFCustomFilter = {};
//         let filterVal1: string[] = [];
//         filterVal1.push(RAFPortalType.PartnerConnect);
//         filter1.Operator = RAFCustomOperator.Equal;
//         filter1.Value = filterVal1;
//         filter1.Field = propertyOf<WorkspaceRow>("PortalType");
//         customFilter.Rules.push(filter1);

//         listServiceRequest.CustomFilter = customFilter;
//         return repositoryActions.postDataAndGetResponse(
//             `Portal/List`,
//             listServiceRequest,
//             null,
//             ContentType.applicationJson, false
//         ).then((response) => {
//             if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
//                 resolve(response.data.Entities);
//             } else {
//                 resolve(null);
//             }

//         })
//             .catch((error) => error);
//     });
// }

export const getWorkspaceViewItems = async (workspaceItems: WorkspaceItemRow[]) => {
    const promises = workspaceItems.map((x) => getWorkspaceViewItem(
        x
    ));
    const results = await Promise.all(promises);
    return (results);
};

const getWorkspaceViewItem = (workspaceItem: WorkspaceItemRow) => {
    return new Promise<PortalTreeViewItemRow>(async (resolve) => {
        const viewItems = await getViewsReportsDashboards(
            workspaceItem.Entity,
            true,
            false
        );
        const tableViews = isNotEmptyArray(viewItems) ? viewItems.filter(x => x.Category === RAFReportType.Table) : [];
        //ParentId: workspaceItem.Entity,
        const ParentId = workspaceItem.Entity;
        const objectArrayWithParentId = isNotEmptyArray(tableViews) ? tableViews.map((obj) => ({
            ...obj, // Copy the existing object properties
            ParentId, // Add the new variable
        })) : [];
        let portalTreeViewItemRow: PortalTreeViewItemRow = new PortalTreeViewItemRow();
        //  {
        //     Name: workspaceItem.Entity,
        //     Text: workspaceItem.Title,
        //     Icon: workspaceItem.Icon,
        //     ParentUID: null,
        //     IsExternalIcon: isNotNullAndUndefined(workspaceItem.Icon) ? true : false,
        //     url: workspaceItem.Relatedto,
        //     nodeChild: objectArrayWithParentId
        // };
        resolve(portalTreeViewItemRow);
    });
};