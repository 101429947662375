import * as R from "ramda";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { getRelatedRecords, getSessionStorage, setSessionStorage } from "../../../RAFComponents/helpers/AppHelper";
import { ContentType, RAFEntityName, StorageKey } from "../../../RAFComponents/helpers/Constants";
import { IsNullOrWhiteSpace, getSaveRequest, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { UserRow } from "../../../SkytrustModules/User/UserRow";
import { RoleRow } from "./RoleRow";

export class RAFPermissionJsonRow {
    Value?: string;
    DisplayName?: string;
}

export const retrieveRoleByUID = (roleUID: string) => {
    let url = 'PermissionGroup/Retrieve';
    return new Promise<RoleRow>((resolve) => {
        if (!IsNullOrWhiteSpace(roleUID)) {
            return repositoryActions.postDataAndGetResponse(
                url,
                { EntityId: roleUID },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let roleRow: RoleRow = response.data.Entity;
                    resolve(roleRow);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

// export const getPermissionsByRoleId = (roleUID: string) => {
//     let url = `RolePermission/GetPermissionsByRole`;
//     return new Promise<RolePermissionRow>((resolve) => {
//         if (!IsNullOrWhiteSpace(roleUID)) {
//             return repositoryActions.postDataAndGetResponse(
//                 url,
//                 { RoleUID: roleUID },
//                 null,
//                 ContentType.applicationJson,
//             ).then((response) => {
//                 if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
//                     let rolePermissionRow: RolePermissionRow = response.data.Entity;
//                     resolve(rolePermissionRow);
//                 } else {
//                     resolve(new RolePermissionRow());
//                 }
//             }).catch((error) => error);
//         } else {
//             resolve(null);
//         }
//     });
// };

export const getAllPermissions = () => {
    let url = `Permission/List`;
    return new Promise<RAFPermissionJsonRow[]>((resolve) => {
        const allPermissionsStorage = getSessionStorage(StorageKey.all_Permissions, true);
        if (isNotNullAndUndefined(allPermissionsStorage) && !IsNullOrWhiteSpace(allPermissionsStorage)) {
            const allPermissions: RAFPermissionJsonRow[] = JSON.parse(allPermissionsStorage);
            if (isNotNullAndUndefined(allPermissions)) {
                resolve(allPermissions);
            }
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                null,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    const allPermissions: RAFPermissionJsonRow[] = response.data.Permission;
                    const uniquePermissions = R.uniq(allPermissions);

                    setSessionStorage(StorageKey.all_Permissions, true, JSON.stringify(uniquePermissions));
                    resolve(uniquePermissions);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        }
    });
};

export const saveRoleRow = (roleRow: RoleRow) => {
    return new Promise<string>((resolve) => {
        repositoryActions.postDataAndGetResponse('PermissionGroup/Save', getSaveRequest(roleRow, roleRow.UID), null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.EntityId);
                } else {
                    resolve(null);
                }
            });
    });
};

// export const saveRolePermissions = (rolePermissionRow: RolePermissionRow) => {
//     return new Promise<string>((resolve) => {
//         repositoryActions.postDataAndGetResponse('RolePermission/Save', getSaveRequest(rolePermissionRow, rolePermissionRow.UID), null, ContentType.applicationJson)
//             .then((response) => {
//                 if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
//                     resolve(response.data.EntityId);
//                 } else {
//                     resolve(null);
//                 }
//             });
//     });
// };

export const getAllRoleList = () => {
    let listServiceRequest = new ListServiceRequest();
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    listServiceRequest.CustomFilter = customFilter;
    return new Promise<RoleRow[]>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            `PermissionGroup/List`,
            listServiceRequest,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)) {
                resolve(response.data.Entities);
            } else {
                resolve(null);
            }
        }).catch((error) => error);
    });
};

export const getRoleListByRoleName = (roleName?: string) => {
    return new Promise<RoleRow[]>((resolve) => {
        if (isNotNullAndUndefined(roleName)) {
            let listServiceRequest = new ListServiceRequest();
            let customFilter: RAFCustomFilter = {};
            customFilter.Condition = "and";
            customFilter.Rules = [];

            let filter: RAFCustomFilter = {};
            let filterVal: string[] = [];
            filterVal.push(roleName);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterVal;
            filter.Field = propertyOf<RoleRow>("PermissionGroupName");
            customFilter.Rules.push(filter);

            listServiceRequest.CustomFilter = customFilter;

            return repositoryActions.postDataAndGetResponse(
                `PermissionGroup/List`,
                listServiceRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};

export const checkIsRoleLinkedWithUser = (roleID: string) => {
    return new Promise<Boolean>(async (resolve) => {
        if (isNotNullAndUndefined(roleID)) {
            const userModule =
                RAFEntityName.User;
            const relatedItems = await getRelatedRecords(
                userModule,
                null,
                propertyOf<UserRow>("PermissionGroupUID"),
                roleID,
                1,
                0,
                `related_${userModule}`
            );
            if (isNotEmptyArray(relatedItems)) {
                showWarningToast("Permission Group is linked with user. Please unlink the role from user and try again.");
                resolve(true);
            } else {
                resolve(false);
            }
        } else {
            resolve(false);
        }
    });
};