import { atomFamily } from "recoil";
import { getDashboardListByModuleName } from "../../../RAFComponents/Dashboard/DashboardHelper";
import RAFGridColumn from "../../../RAFComponents/Grid/RAFGridColumn";
import {
  getReportListByModuleName,
  getSessionStorage,
  getUserViewsByModuleName,
  setSessionStorage,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  Constants,
  RAFEntityName,
  RAFReportType,
  RAFTreeViewMenu,
  RAFViewType,
  SFColumnType,
  StorageKey,
  getURLPrefixByModuleName,
} from "../../../RAFComponents/helpers/Constants";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  ConvertCollectionName,
  ConvertToElementID,
  ConvertToPascal,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { DashboardJM } from "../../../RAFComponents/models/Common/DashboardJM";
import { ValueJson } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { ReportJM } from "../../../RAFComponents/models/Common/ReportJM";
import { RAFGridView } from "../../../RAFMaster/helpers/RMConstants";
import {
  CanImpersonateUser,
  UserDefaultViewName,
  userFilterTabText,
} from "../../../SkytrustModules/User/UserHelper";
import { SKYPermissionConstants } from "../../../helpers/SKYPermissionConstants";
import {
  EmpLoginStatusText,
  EmpLoginStatusValue,
  MigrationStatus,
} from "../../../helpers/SkyConstants";
import { RoleRow } from "../Role/RoleRow";

export interface RAFDefaultGridColumnProps {
  field: string;
  type?: SFColumnType;
  headerText?: string;
  visible?: boolean;
  //template?: React.ReactElement,
  template?: any;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  valueAccessor?: any;
  //textAlign?: TextAlign;
  //headerTextAlign?: TextAlign;
  isPrimaryKey?: boolean;
  isEditable?: boolean;
  valueJson?: ValueJson[];
}

export const atomSelectedGridItemsState = atomFamily<any, any>({
  key: "selectedGridItemsState",
  default: null,
});

export const atomEditingRecordId = atomFamily<any, any>({
  key: "editingRecordIdState",
  default: null,
});

export const atomSelectedViewHeaderItem = atomFamily<any, any>({
  key: "selectedViewHeaderItemState",
  default: null,
  //used to store recently viewed view header for each module like Dashboard(1) or List(2) or Analytics(3)
  //use constant instead of 1 in default
});

export const atomSelectedViewState = atomFamily<any, any>({
  key: "selectedViewState",
  default: null as RAFTreeViewMenu,
  //used to store recently viewed view for each module
});

export const atomSelectedGridFilterState = atomFamily<any, any>({
  key: "selectedGridFilterState",
  default: null as RAFTreeViewMenu,
});

export const atomSelectedRelatedSectionState = atomFamily<any, any>({
  key: "selectedRelatedSectionState",
  default: null as RAFTreeViewMenu,
});

// export const atomGridRefreshTime = atomFamily<any, any>({
//     key: "gridRefreshTimeState",
//     default: null,
// });

// export const getDefaultViewAndGridColumns = (moduleName: string) => {
//     let defaultViewAndGridColumns = { defaultView: null as RAFViewRow, defaultGridColumns: null as RAFDefaultGridColumnProps[] };
//     if (moduleName === RAFEntityName.Contact) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "allcontacts_default",
//             DisplayName: "All Contacts",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };

//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "ContactName",
//                 headerText: "Contact Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Account",
//                 headerText: "Company",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Type",
//                 headerText: "Type",
//                 type: SFColumnType.string
//             }
//         ];
//     } else if (moduleName === RAFEntityName.Account) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "allaccounts_default",
//             DisplayName: "All Accounts",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };

//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "AccountName",
//                 headerText: "Account Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Website",
//                 headerText: "Website",
//                 type: SFColumnType.string
//             }
//         ];
//     } else if (moduleName === RAFEntityName.Ticket) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "alltickets_default",
//             DisplayName: "All Tickets",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };
//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "Subject",
//                 headerText: "Subject",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "TicketId",
//                 headerText: "Ticket Id",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Account",
//                 headerText: "Company Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Contact",
//                 headerText: "Contact Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Priority",
//                 headerText: "Priority",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "RequestOrigin",
//                 headerText: "Request Origin",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "TicketType",
//                 headerText: "Ticket Type",
//                 type: SFColumnType.string
//             },
//         ];
//     } else if (moduleName === RAFEntityName.Deal) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "alldeals_default",
//             DisplayName: "All Deals",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };

//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "DealName",
//                 headerText: "Deal Name",
//                 type: SFColumnType.string
//             }
//         ];
//     } else {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: 'all_default',
//             DisplayName: "All Views",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };
//     }
//     return defaultViewAndGridColumns;
// };

//export const getListPageiewMenuItem = (allViews: RAFViewRow[], allReports: ReportJM[], allDashboards: DashboardJM[]) => {
export const getViewsReportsDashboards = (
  moduleName: string,
  addEmptyView?: boolean,
  disableDashboardAnalytic?: boolean,
  permissionValue?: RoleRow
) => {
  return new Promise<RAFTreeViewMenu[]>(async (resolve) => {
    let allViews = await getUserViewsByModuleName(moduleName, addEmptyView);
    if (moduleName === RAFEntityName.Portal) {
      const canImpersonateUser = await CanImpersonateUser();
      if (canImpersonateUser) {
        if (
          isNotEmptyArray(allViews) &&
          isNullOrUndefined(allViews.find((x) => x.ViewName === "SwitchTenant"))
        )
          allViews.push({
            UID: null,
            ViewName: "SwitchTenant",
            DisplayName: "Switch Tenant",
            ViewType: RAFReportType.Table,
            SettingsJson: {},
            IsSystem: true,
            //url: null,
            //gridColumns: null
          });
      }
    }

    let allReports: ReportJM[] = null;
    let allDashboards: DashboardJM[] = null;

    if (disableDashboardAnalytic !== true) {
      allReports = await getReportListByModuleName(moduleName);
      allDashboards = await getDashboardListByModuleName(moduleName);
    }

    let viewMenuDataSource: RAFTreeViewMenu[] = [];
    if (isNotEmptyArray(allViews)) {
      allViews.forEach((item) => {
        let viewData: RAFTreeViewMenu = {
          UID: item.UID,
          Name: item.ViewName,
          DisplayName: item.DisplayName,
          Category:
            item.ViewType === "group"
              ? RAFReportType.Group
              : RAFReportType.Table,
          IsPrivate: item.SettingsJson && item.SettingsJson.IsPrivate,
          IsSystem: item.IsSystem,
          ViewType: item.IsSystem
            ? RAFViewType.SystemView
            : item.SettingsJson && item.SettingsJson.IsPrivate
              ? RAFViewType.MyView
              : RAFViewType.SharedView,
          PermissionName: item.PermissionName ?? `${item.Entity}::List`,
          //Isdefault: item.Isdefault
        };
        viewMenuDataSource.push(viewData);
      });
    }

    if (isNotNullAndUndefined(allReports) && allReports.length > 0) {
      allReports.forEach((item) => {
        let reportData: RAFTreeViewMenu = {
          UID: item.UID,
          Name: item.Title,
          DisplayName: item.Title,
          Category: RAFReportType.Analytic,
          ViewType: item.ReportType,
          ParentID: item.EntityViewUID,
        };
        viewMenuDataSource.push(reportData);
      });
    }

    if (isNotNullAndUndefined(allDashboards) && allDashboards.length > 0) {
      allDashboards.forEach((item) => {
        let dashboardData: RAFTreeViewMenu = {
          UID: item.UID,
          Name: item.Title,
          DisplayName: item.Title,
          Category: RAFReportType.Dashboard,
        };
        viewMenuDataSource.push(dashboardData);
      });
    }
    resolve(viewMenuDataSource);
  });
};

class RAFViewRowWithColumns extends RAFViewRow {
  url?: string;
  gridColumns?: RAFDefaultGridColumnProps[];
}

export const getDefaultGridColumnsBymoduleName = (
  moduleName: string,
  viewName: string
) => {
  let defaultGridColumns: RAFDefaultGridColumnProps[];
  if (moduleName === RAFEntityName.Employee) {
    if (viewName === "CurrentEmployee") {
      defaultGridColumns = [
        {
          field: "FirstName",
          headerText: "First Name",
          type: SFColumnType.string,
        },
        {
          field: "LastName",
          headerText: "Surname",
          type: SFColumnType.string,
        },
        {
          field: "UserName",
          headerText: "User Name",
          type: SFColumnType.string,
        },
        {
          field: "EmployeeId",
          headerText: "Employee Id",
          type: SFColumnType.string,
        },
        {
          field: "WorkEmail",
          headerText: "Work Email",
          type: SFColumnType.string,
        },
        {
          field: "PersonalEmail",
          headerText: "Personal Email",
          type: SFColumnType.string,
        },
        {
          field: "JobTitle",
          headerText: "Job Title",
          type: SFColumnType.string,
        },
        {
          field: "Department",
          headerText: "Department",
          type: SFColumnType.string,
        },
        {
          field: "Location",
          headerText: "Location/Project",
          type: SFColumnType.string,
        },
        {
          field: "UserGroup",
          headerText: "UserGroup",
          type: SFColumnType.string,
        },
      ];
    } else if (viewName === "PastEmployee") {
      defaultGridColumns = [
        {
          field: "FirstName",
          headerText: "First Name",
          type: SFColumnType.string,
        },
        {
          field: "LastName",
          headerText: "Surname",
          type: SFColumnType.string,
        },
        {
          field: "EmployeeId",
          headerText: "Employee Id",
          type: SFColumnType.string,
        },
        {
          field: "WorkEmail",
          headerText: "Work Email",
          type: SFColumnType.string,
        },
        {
          field: "PersonalEmail",
          headerText: "Personal Email",
          type: SFColumnType.string,
        },
        {
          field: "JobTitle",
          headerText: "Job Title",
          type: SFColumnType.string,
        },
        {
          field: "Department",
          headerText: "Department",
          type: SFColumnType.string,
        },
        {
          field: "Location",
          headerText: "Location/Project",
          type: SFColumnType.string,
        },
        {
          field: "UserGroup",
          headerText: "UserGroup",
          type: SFColumnType.string,
        },
      ];
    } else if (viewName === "SubcontractorsEmployee") {
      defaultGridColumns = [
        {
          field: "FirstName",
          headerText: "First Name",
          type: SFColumnType.string,
        },
        {
          field: "LastName",
          headerText: "Surname",
          type: SFColumnType.string,
        },
        {
          field: "EmployeeId",
          headerText: "Employee Id",
          type: SFColumnType.string,
        },
        {
          field: "WorkEmail",
          headerText: "Work Email",
          type: SFColumnType.string,
        },
        {
          field: "PersonalEmail",
          headerText: "Personal Email",
          type: SFColumnType.string,
        },
        {
          field: "Subcontractor",
          headerText: "Subcontractor",
          type: SFColumnType.string,
        },
        {
          field: "JobTitle",
          headerText: "Job Title",
          type: SFColumnType.string,
        },
        {
          field: "Department",
          headerText: "Department",
          type: SFColumnType.string,
        },
        {
          field: "Location",
          headerText: "Location/Project",
          type: SFColumnType.string,
        },
        {
          field: "UserGroup",
          headerText: "UserGroup",
          type: SFColumnType.string,
        },
      ];
    } else if (viewName === MigrationStatus.Migrated) {
      defaultGridColumns = [
        {
          field: "UserName",
          headerText: "UserName",
          type: SFColumnType.string,
        },
        {
          field: "NewUsername",
          headerText: "New Username",
          type: SFColumnType.string,
        },
        {
          field: "MigrationStatus",
          headerText: "Migration Status",
          type: SFColumnType.string,
        },
        {
          field: "ClientName",
          headerText: "Client Name",
          type: SFColumnType.string,
        },
      ];
    } else {
      defaultGridColumns = [
        {
          field: "FirstName",
          headerText: "First Name",
          type: SFColumnType.string,
        },
        {
          field: "LastName",
          headerText: "Surname",
          type: SFColumnType.string,
        },
        {
          field: "EmployeeId",
          headerText: "Employee Id",
          type: SFColumnType.string,
        },
      ];
    }

    defaultGridColumns = [
      {
        field: "EmployeeId",
        headerText: "Employee Id",
        type: SFColumnType.string,
      },
      {
        field: "FirstName",
        headerText: "First Name",
        type: SFColumnType.string,
      },
      {
        field: "LastName",
        headerText: "Surname",
        type: SFColumnType.string,
      },
      {
        field: "JobTitle",
        headerText: "Job Title",
        type: SFColumnType.string,
      },
      {
        field: "Department",
        headerText: "Department",
        type: SFColumnType.string,
      },
      {
        field: "Location",
        headerText: "Location/Project",
        type: SFColumnType.string,
      },
      {
        field: "UserName",
        headerText: "User Name",
        type: SFColumnType.string,
      },
      {
        field: "UserGroup",
        headerText: "Permission Group",
        type: SFColumnType.string,
      },
      {
        field: "Portal",
        headerText: "Business Unit",
        type: SFColumnType.string,
      },
    ];
  } else if (moduleName === RAFEntityName.User) {
    let migrationStatus: ValueJson[] = [
      // {
      //     Id: 1,
      //     Name: EmpLoginStatusValue.Unverified.toString(),
      //     DisplayName: EmpLoginStatusText.Unverified,
      // },
      {
        Id: 1,
        Name: EmpLoginStatusValue.SecureIDPending.toString(),
        DisplayName: EmpLoginStatusText.SecureIDPending,
      },
      {
        Id: 2,
        Name: EmpLoginStatusValue.MSSSOPending.toString(),
        DisplayName: EmpLoginStatusText.MSSSOPending,
      },
      {
        Id: 3,
        Name: EmpLoginStatusValue.NewuserPending.toString(),
        DisplayName: EmpLoginStatusText.NewuserPending,
      },
    ];
    if (viewName === UserDefaultViewName.UserUnverified) {
      defaultGridColumns = [
        // {
        //     field: "Employee",
        //     headerText: "User",
        //     type: SFColumnType.string
        // },
        {
          field: "FirstName",
          headerText: "First Name",
          type: SFColumnType.string,
        },
        {
          field: "LastName",
          headerText: "Last Name",
          type: SFColumnType.string,
        },
        {
          field: "UserName",
          headerText: "Username",
          type: SFColumnType.string,
        },
        // {
        //     field: "UserDisplayName",
        //     headerText: "Display Username",
        //     type: SFColumnType.string
        // },
        {
          field: "OldUserName",
          headerText: "Old Username",
          type: SFColumnType.string,
        },
        // {
        //     field: "LoginFlow",
        //     headerText: "Status",
        //     type: SFColumnType.dropdown,
        //     valueJson: migrationStatus,
        // },
      ];
    } else if (viewName === userFilterTabText.SecureID) {
      defaultGridColumns = [
        // {
        //     field: "FirstName",
        //     headerText: "First Name",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "LastName",
        //     headerText: "Last Name",
        //     type: SFColumnType.string
        // },
        {
          field: "UserName",
          headerText: "Username",
          type: SFColumnType.string,
        },
        // {
        //     field: "UserDisplayName",
        //     headerText: "Display Username",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "OldUserName",
        //     headerText: "Old Username",
        //     type: SFColumnType.string
        // },
        {
          field: "Employee",
          headerText: "Employee",
          type: SFColumnType.lookup,
        },
        // {
        //     field: "EmployeeLinked",
        //     headerText: "Employee Linked",
        //     type: SFColumnType.boolean
        // },
        {
          field: "TwoFactor",
          headerText: "Two Factor",
          type: SFColumnType.boolean,
        },
        {
          field: "PermissionGroup",
          headerText: "Permission Group",
          type: SFColumnType.string,
        },
        {
          field: "ModifiedBy",
          headerText: "Modified By",
          type: SFColumnType.lookup,
        },
        {
          field: "ModifiedDate",
          headerText: "Modified Date",
          type: SFColumnType.date,
        },
      ];
    } else if (viewName === userFilterTabText.InvitesPending) {
      defaultGridColumns = [
        // {
        //     field: "FirstName",
        //     headerText: "First Name",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "LastName",
        //     headerText: "Last Name",
        //     type: SFColumnType.string
        // },
        {
          field: "UserName",
          headerText: "Username",
          type: SFColumnType.string,
        },
        // {
        //     field: "UserDisplayName",
        //     headerText: "Display Username",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "OldUserName",
        //     headerText: "Old Username",
        //     type: SFColumnType.string
        // },
        {
          field: "Employee",
          headerText: "Employee",
          type: SFColumnType.lookup,
        },
        // {
        //     field: "EmployeeLinked",
        //     headerText: "Employee Linked",
        //     type: SFColumnType.boolean
        // },
        {
          field: "LoginFlow",
          headerText: "Login Method",
          type: SFColumnType.dropdown,
          valueJson: migrationStatus,
        },
        {
          field: "LoginStatus",
          headerText: "Login Status",
          type: SFColumnType.dropdown,
          valueJson: migrationStatus,
        },
        {
          field: "PermissionGroup",
          headerText: "Permission Group",
          type: SFColumnType.string,
        },
        {
          field: "ModifiedBy",
          headerText: "Modified By",
          type: SFColumnType.lookup,
        },
        {
          field: "ModifiedDate",
          headerText: "Modified Date",
          type: SFColumnType.date,
        },
      ];
    } else if (viewName === userFilterTabText.AllUser) {
      defaultGridColumns = [
        // {
        //     field: "FirstName",
        //     headerText: "First Name",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "LastName",
        //     headerText: "Last Name",
        //     type: SFColumnType.string
        // },
        {
          field: "UserName",
          headerText: "Username",
          type: SFColumnType.string,
        },
        // {
        //     field: "UserDisplayName",
        //     headerText: "Display Username",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "OldUserName",
        //     headerText: "Old Username",
        //     type: SFColumnType.string
        // },
        {
          field: "Employee",
          headerText: "Employee",
          type: SFColumnType.lookup,
        },
        // {
        //     field: "EmployeeLinked",
        //     headerText: "Employee Linked",
        //     type: SFColumnType.boolean
        // },
        {
          field: "LoginFlow",
          headerText: "Login Method",
          type: SFColumnType.dropdown,
          valueJson: migrationStatus,
        },
        {
          field: "LoginStatus",
          headerText: "Login Status",
          type: SFColumnType.dropdown,
          valueJson: migrationStatus,
        },
        {
          field: "PermissionGroup",
          headerText: "Permission Group",
          type: SFColumnType.string,
        },
        {
          field: "ModifiedBy",
          headerText: "Modified By",
          type: SFColumnType.lookup,
        },
        {
          field: "ModifiedDate",
          headerText: "Modified Date",
          type: SFColumnType.date,
        },
      ];
    } else {
      defaultGridColumns = [
        // {
        //     field: "FirstName",
        //     headerText: "First Name",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "LastName",
        //     headerText: "Last Name",
        //     type: SFColumnType.string
        // },
        {
          field: "UserName",
          headerText: "Username",
          type: SFColumnType.string,
        },
        // {
        //     field: "UserDisplayName",
        //     headerText: "Display Username",
        //     type: SFColumnType.string
        // },
        // {
        //     field: "OldUserName",
        //     headerText: "Old Username",
        //     type: SFColumnType.string
        // },
        {
          field: "Employee",
          headerText: "Employee",
          type: SFColumnType.lookup,
        },
        // {
        //     field: "EmployeeLinked",
        //     headerText: "Employee Linked",
        //     type: SFColumnType.boolean
        // },
        // {
        //     field: "LoginFlow",
        //     headerText: "Status",
        //     type: SFColumnType.dropdown,
        //     valueJson: migrationStatus,
        // },
        {
          field: "PermissionGroup",
          headerText: "Permission Group",
          type: SFColumnType.string,
        },
        {
          field: "ModifiedBy",
          headerText: "Modified By",
          type: SFColumnType.lookup,
        },
        {
          field: "ModifiedDate",
          headerText: "Modified Date",
          type: SFColumnType.date,
        },
      ];
    }
    // if (viewName === userFilterTabText.InvitesPending) {
    //     let loginFlow: ValueJson[] = [
    //         {
    //             Id: 0,
    //             Name: EmpLoginStatusValue.SecureIDPending.toString(),
    //             DisplayName: 'YES',
    //         },
    //         {
    //             Id: 1,
    //             Name: EmpLoginStatusValue.NewuserPending.toString(),
    //             DisplayName: 'NO',
    //         },
    //     ];
    //     defaultGridColumns = [
    //         {
    //             field: "FirstName",
    //             headerText: "First Name",
    //             type: SFColumnType.string
    //         },
    //         {
    //             field: "LastName",
    //             headerText: "Last Name",
    //             type: SFColumnType.string
    //         },
    //         {
    //             field: "UserName",
    //             headerText: "Username",
    //             type: SFColumnType.string
    //         },
    //         {
    //             field: "Employee",
    //             headerText: "Employee",
    //             type: SFColumnType.lookup
    //         },
    //         {
    //             field: "LoginFlow",
    //             headerText: "Existing User",
    //             type: SFColumnType.dropdown,
    //             valueJson: loginFlow,
    //         },
    //     ];
    // }
  } else if (moduleName === RAFEntityName.Incident) {
    if (viewName === "AllIncidents") {
      defaultGridColumns = [
        {
          field: "BusinessUnit",
          headerText: "Business Unit",
          type: SFColumnType.string,
        },
        {
          field: "InternalReferenceId",
          headerText: "Ref ID",
          type: SFColumnType.string,
        },
        {
          field: "IncidentDateTime",
          headerText: "Date",
          type: SFColumnType.datetime,
        },
        {
          field: "Location",
          headerText: "Location/Project",
          type: SFColumnType.string,
        },
        {
          field: "Department",
          headerText: "Department",
          type: SFColumnType.string,
        },
        {
          field: "Area",
          headerText: "Area",
          type: SFColumnType.string,
        },
        {
          field: "ReportedBy",
          headerText: "Reported By",
          type: SFColumnType.string,
        },
        {
          field: "PersonInvolved",
          headerText: "Injured Person",
          type: SFColumnType.string,
        },
      ];
    }
  } else if (moduleName === RAFEntityName.Portal) {
    defaultGridColumns = [
      {
        field: "PortalName",
        headerText: "Business Unit",
        type: SFColumnType.string,
      },
      {
        field: "IsActive",
        headerText: "IsActive",
        type: SFColumnType.boolean,
      },
    ];
  } else if (moduleName === RAFEntityName.Tenant) {
    defaultGridColumns = [
      {
        field: "DisplayName",
        headerText: "DisplayName",
        type: SFColumnType.string,
      },
      {
        field: "TenantName",
        headerText: "TenantName",
        type: SFColumnType.string,
      },
      {
        field: "SubDomain",
        headerText: "SubDomain",
        type: SFColumnType.string,
      },
    ];
  }
  return defaultGridColumns;
};

const allModulesDefaultViews: {
  moduleName: string;
  views: RAFViewRowWithColumns[];
}[] = [
    {
      moduleName: RAFEntityName.Employee,
      views: [
        {
          UID: null,
          ViewName: "CurrentEmployee",
          DisplayName: "Current Employees",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "Employee/CurrentEmployee",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.Employee,
            "CurrentEmployee"
          ),
          Entity: RAFEntityName.Employee,
          PermissionName: SKYPermissionConstants.HumanResourcesEmployeesView,
        },
        {
          UID: null,
          ViewName: "PastEmployee",
          DisplayName: "Past Employees",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "Employee/PastEmployee",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.Employee,
            "PastEmployee"
          ),
          Entity: RAFEntityName.Employee,
          PermissionName: SKYPermissionConstants.HumanResourcesEmployeesView,
        },
        {
          UID: null,
          ViewName: "SubcontractorsEmployee",
          DisplayName: "Subcontractor Employees",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "Employee/SubcontractorsEmployee",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.Employee,
            "SubcontractorsEmployee"
          ),
          Entity: RAFEntityName.Employee,
          PermissionName: SKYPermissionConstants.HumanResourcesEmployeesView,
        },
        {
          UID: null,
          ViewName: UserDefaultViewName.UserVerified,
          DisplayName: "User Logins",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "User/List",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.User,
            "AllUser"
          ),
          Entity: RAFEntityName.User,
        },
        {
          UID: null,
          ViewName: UserDefaultViewName.UserUnverified,
          DisplayName: "Unverified Logins",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "User/List",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.User,
            "AllUser"
          ),
          Entity: RAFEntityName.User,
        },
        // {
        //     UID: null,
        //     ViewName: "EmployeeLoginStatus",
        //     DisplayName: "Login Username & Status",
        //     ViewType: RAFReportType.Table,
        //     SettingsJson: {},
        //     IsSystem: true,
        //     url: 'Employee/EmployeeLoginStatus',
        //     gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Employee, 'EmployeeLoginStatus')
        // },
        // {
        //     UID: null,
        //     ViewName: MigrationStatus.Migrated,
        //     DisplayName: "Manage SecureID Migration",
        //     ViewType: RAFReportType.Table,
        //     SettingsJson: {},
        //     IsSystem: true,
        //     url: `Employee/${MigrationStatus.Migrated}`,
        //     gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Employee, MigrationStatus.Migrated)
        // },
        // {
        //     UID: null,
        //     ViewName: 'UserLoginStatus',
        //     DisplayName: "Manage Users",
        //     ViewType: RAFReportType.Table,
        //     SettingsJson: {},
        //     IsSystem: true,
        //     url: 'User/List',
        //     gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.User, 'AllUser')
        // },
      ],
    },
    {
      moduleName: RAFEntityName.User,
      views: [
        {
          UID: null,
          ViewName: UserDefaultViewName.UserVerified,
          DisplayName: "Verified",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "User/List",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.User,
            "AllUser"
          ),
        },
        {
          UID: null,
          ViewName: UserDefaultViewName.UserUnverified,
          DisplayName: "Unverified",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "User/List",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.User,
            "AllUser"
          ),
        },
      ],
    },
    {
      moduleName: RAFEntityName.Incident,
      views: [
        {
          UID: null,
          ViewName: "AllIncidents",
          DisplayName: "All Incidents",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "Incident/List",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.Employee,
            "AllIncidents"
          ),
        },
      ],
    },
    {
      moduleName: RAFEntityName.Tenant,
      views: [
        {
          UID: null,
          ViewName: "AllTenant",
          DisplayName: "All Tenant",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "Tenant/ListAll",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.Tenant,
            "AllTenant"
          ),
        },
      ],
    },
    {
      moduleName: RAFEntityName.Portal,
      views: [
        {
          UID: null,
          ViewName: "MyBusinessUnits",
          DisplayName: "My Business Units",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          url: "Portal/List",
          gridColumns: getDefaultGridColumnsBymoduleName(
            RAFEntityName.Portal,
            "AllPortal"
          ),
        },
        // {
        //     UID: null,
        //     ViewName: "SwitchTenant",
        //     DisplayName: "Switch Tenant",
        //     ViewType: RAFReportType.Table,
        //     SettingsJson: {},
        //     IsSystem: true,
        //     url: null,
        //     gridColumns: null
        // }
      ],
    },
    {
      moduleName: RAFEntityName.InspectionLibraryCategoryType,
      views: [
        {
          UID: null,
          ViewName: "Inspection",
          DisplayName: "Inspections Pro",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          url: "ESIOForms/Inspection/List",
          IsSystem: true,
          PermissionName: SKYPermissionConstants.InspectionsProView,
          //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
        },
        {
          UID: null,
          ViewName: "Permit",
          DisplayName: "Permits",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          url: "ESIOForms/Permit/List",
          IsSystem: true,
          PermissionName: SKYPermissionConstants.InspectionsPermitView,
          //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
        },
        {
          UID: null,
          ViewName: "Evaluation",
          DisplayName: "Evaluations",
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          url: "ESIOForms/Evaluation/List",
          IsSystem: true,
          PermissionName: SKYPermissionConstants.InspectionsEvaluationView,
          //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
        },
      ],
    },
  ];

export function getDefaultViewsByModuleName(
  moduleName: string
): RAFViewRowWithColumns[] {
  if (!IsNullOrWhiteSpace(moduleName)) {
    let defaultItem = allModulesDefaultViews.find(
      (x) => x.moduleName === moduleName
    );
    if (
      isNotNullAndUndefined(defaultItem) &&
      isNotNullAndUndefined(defaultItem.views)
    ) {
      return defaultItem.views;
    } else {
      let defaultView: RAFViewRowWithColumns[] = [
        {
          UID: null,
          ViewName: `all${moduleName}_default`,
          DisplayName: `All ${ConvertCollectionName(
            ConvertToPascal(moduleName)
          )}`,
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          //url: 'DataList/List',
          //Isdefault: true,
        },
      ];
      return defaultView;
    }
  } else {
    return null;
  }
}

export const getUrlByModuleAndView = (
  moduleName: string,
  currentView: RAFTreeViewMenu,
  URL?: string
) => {
  let url = isNotNullAndUndefined(URL)
    ? URL
    : `${getURLPrefixByModuleName(moduleName)}/List`;
  if (isNotNullAndUndefined(currentView)) {
    const defaultViews = getDefaultViewsByModuleName(moduleName);
    let item = isNotNullAndUndefined(defaultViews)
      ? defaultViews.find((x) => x.ViewName === currentView.Name)
      : null;
    if (isNotNullAndUndefined(item) && isNotNullAndUndefined(item.url)) {
      url = item.url;
    }
  }
  if (url.includes(Constants.baseAPIUrl)) {
    return url;
  } else {
    return `${Constants.baseAPIUrl}${url}`;
  }
};

export const getGridColumnsByModuleAndView = (
  moduleName: string,
  currentView: RAFTreeViewMenu
) => {
  const defaultViews = getDefaultViewsByModuleName(moduleName);
  let item =
    isNotNullAndUndefined(defaultViews) && isNotNullAndUndefined(currentView)
      ? defaultViews.find((x) => x.ViewName === currentView.Name)
      : null;
  if (isNullOrUndefined(item) && isNotEmptyArray(defaultViews)) {
    item = defaultViews[0];
  }
  if (
    isNotNullAndUndefined(item) &&
    isNotNullAndUndefined(item.gridColumns) &&
    item.gridColumns.length > 0
  ) {
    return item.gridColumns.map((item, index) => {
      return (
        <RAFGridColumn
          key={index}
          field={item.field}
          headerText={item.headerText}
          type={item.type}
          valueJson={item.valueJson ?? null}
        />
      );
    });
  }
};

export const getViewElementId = (viewName: string, itemUID: string) => {
  let elementId;
  if (!IsNullOrWhiteSpace(viewName) && !IsNullOrWhiteSpace(itemUID)) {
    elementId = ConvertToElementID(`${viewName}_${itemUID}`);
  } else if (!IsNullOrWhiteSpace(itemUID)) {
    elementId = itemUID;
  } else if (!IsNullOrWhiteSpace(viewName)) {
    elementId = ConvertToElementID(viewName);
  }
  return elementId;
};

export const getDefaultActiveCurrentViewFromList = async (
  moduleName: string,
  viewId: string,
  selectedViewType: string,
  viewListItem: RAFTreeViewMenu[],
  disableDashboardAnalytic: boolean,
  permissionValue?: RoleRow,
  viewName?: string
) => {
  return new Promise<RAFTreeViewMenu>(async (resolve) => {
    const allViewMenuDataSource = isNotEmptyArray(viewListItem)
      ? viewListItem
      : await getViewsReportsDashboards(
        moduleName,
        disableDashboardAnalytic,
        false
      );

    const viewMenuDataSource = isNotEmptyArray(allViewMenuDataSource)
      ? allViewMenuDataSource.filter((x) => {
        const permissionName = x.PermissionName ?? `${moduleName}::List`;
        const permission = isNotNullAndUndefined(permissionValue)
          ? hasPermission(permissionValue, permissionName)
          : true;
        return permission;
      })
      : [];

    let recentViews =
      JSON.parse(
        getSessionStorage(StorageKey.recentViewId_modulename + moduleName, true)
      ) || [];

    let recentViewId = isNotNullAndUndefined(viewId)
      ? viewId
      : recentViews["viewId"];

    const recentViewName = isNotNullAndUndefined(viewId)
      ? viewName
      : recentViews["viewName"];

    let currentView: RAFTreeViewMenu;

    if (
      isNotNullAndUndefined(recentViewId) &&
      !IsNullOrWhiteSpace(recentViewId) &&
      isNotEmptyArray(viewMenuDataSource)
    ) {
      currentView = viewMenuDataSource.find(
        (x) => x.UID === recentViewId || x.Name === recentViewId
      );
    }

    if (
      isNullOrUndefined(currentView) &&
      isNotNullAndUndefined(recentViewName) &&
      !IsNullOrWhiteSpace(recentViewName) &&
      isNotEmptyArray(viewMenuDataSource)
    ) {
      currentView = viewMenuDataSource.find(
        (x) => x.Name === recentViewId || x.Name === recentViewName
      );
    }

    if (
      isNotNullAndUndefined(currentView) &&
      isNotNullAndUndefined(currentView.UID)
    ) {
      if (
        isNotNullAndUndefined(selectedViewType) &&
        currentView.Category !== selectedViewType
      ) {
        currentView = null;
      }
    }
    if (isNullOrUndefined(currentView)) {
      let allViews = isNotEmptyArray(viewMenuDataSource)
        ? moduleName === RAFEntityName.Template
          ? viewMenuDataSource
          : viewMenuDataSource.filter((x) => x.Category === selectedViewType)
        : null;
      if (isNotEmptyArray(allViews)) {
        let defaultViews = allViews.filter((x) => x.IsDefault === true);
        if (isNotEmptyArray(defaultViews)) {
          currentView = defaultViews[0];
        } else {
          currentView = allViews[0];
        }
      } else {
        const emptyView: RAFTreeViewMenu = {
          UID: null,
          ViewType: null,
          ParentID: null,
          IsSystem: null,
          IsPrivate: null,
          DisplayName: "No view found",
          Name: "No view found",
          Category: selectedViewType,
        };
        currentView = emptyView;
      }
    }
    if (
      isNotNullAndUndefined(currentView) &&
      (isNotNullAndUndefined(currentView.UID) ||
        isNotNullAndUndefined(currentView.Name))
    ) {
      setSessionStorage(
        StorageKey.recentViewId_modulename + moduleName,
        true,
        JSON.stringify({
          viewId: isNotNullAndUndefined(currentView.UID)
            ? currentView.UID
            : currentView.Name,
          viewName: currentView.DisplayName,
          category: currentView.Category,
        })
      );
    }
    resolve(currentView);
  });
};

export const atomOnlineOfflineState = atomFamily<any, any>({
  key: "onlineOfflineState",
  default: null,
});

export const atomSelectedGridView = atomFamily<any, any>({
  key: "selectedGridView",
  default: RAFGridView.ListView,
});

export const atomToggleGridCardContent = atomFamily<any, any>({
  key: "toggleGridCardContent",
  default: "card", //grid
});
