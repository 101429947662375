import * as React from 'react';
import { Fragment, PropsWithChildren } from 'react';
import RAFDropdown from '../../../RAFComponents/Inputs/RAFDropdown';
import { RAFDefaultFieldProps, RAFDropdownFieldProps } from '../../../RAFComponents/Inputs/RFFUtils';

function SkyDropdownList<T>(
    { field, label, required, showLabel, width, disabled, validate, onChanged, children, ...props }: PropsWithChildren<RAFDropdownFieldProps<T>>,
) {
    return (
        <Fragment>
            <div className="row skyFormField g-0" id={"rafdiv" + field.toString()} >
                <div className="col-md-5 col-xl-3 col-sm-3 leftLabel">
                    {showLabel && showLabel === true &&
                        <label className={(required ? "form-label required" : "form-label")}><>{label || field}</></label>
                    }
                </div>
                <div className={`${props.inputFieldClassName ? props.inputFieldClassName : 'col-md-7 col-xl-7 col-sm-9 col-xxl-5'} rightData`}>
                    <RAFDropdown<T> field={field} label={label} required={required} showLabel={false} children={children} width={width && width !== null ? width : '100%'} disabled={disabled} validate={validate} onChanged={onChanged} {...props} />
                </div>
            </div>
        </Fragment>
    );
}

SkyDropdownList.defaultProps = RAFDefaultFieldProps;

export default SkyDropdownList;